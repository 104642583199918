import React from "react";
import {
  Icon,
  Dialog,
  DialogContent,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";
import CountriesSelect from "./CountriesSelect";
import EditSchedule from "./more/EditSchedule";

function ConnectPcoModal(props) {
  const {
    open,
    closeModal,
    labels,
    onCountryChange,
    countryValue,
    className,
    phoneNumberValue,
    name,
    phoneOptions,
    onPhoneNumChange,
  } = props;

  return (
    <Dialog
      fullScreen={window.innerWidth < 600}
      open={open}
      onClose={closeModal}
      classes={{
        root: "add-new-number-modal pastorsline",
        paper: `paper number-modal ${
          window.innerWidth < 600 ? "" : "pco-modal-paper"
        }`,
      }}
      maxWidth="xl"
    >
      <Typography className="title">
        PCO
        <Icon className="close-icon" onClick={closeModal}>
          close
        </Icon>
      </Typography>
      <DialogContent className="mb-4 content modal-numbers-wrapper">
        <div className="w-100 pco-content-container">
          <CountriesSelect
            labels={labels}
            value={countryValue}
            onChange={onCountryChange}
            className={`w-100 mb-4 mt-3 ${className}`}
          />

          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            name={name}
            value={phoneNumberValue}
            onChange={onPhoneNumChange}
            className={`w-100 mb-4 mt-3 ${className}`}
          >
            {phoneOptions.map((item, index) => (
              <MenuItem key={index} value={item.key}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
          <EditSchedule onConnectPcoModal={true} />
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ConnectPcoModal;
