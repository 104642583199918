import React from "react";
import { Link } from "react-router-dom";
import { Typography, Avatar } from "@material-ui/core";
import ToggleButton from "./../../atoms/ToggleButton";
import "./../../styles/components/people/people-list.scss";

function PeopleList(props) {
  return (
    <div className="services-wrapper">
      {props.persons.map((person, idx) => (
        <Link
          to={person.target}
          className={
            person.disabled ? "single-person disabled" : "single-person"
          }
          key={idx}
        >
          <div className="left-content">
            {person.withAvatar && (
              <Avatar className="avatar">{person.name.charAt(0)}</Avatar>
            )}
            <Typography>{person.name}</Typography>
          </div>
          <div className="right-content">
            <ToggleButton
              onIcon="notifications"
              offIcon="notifications_none"
              on={person.on}
            />
          </div>
        </Link>
      ))}
    </div>
  );
}
export default PeopleList;
