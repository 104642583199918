import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Tooltip, Tabs, Tab, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import queryString from "query-string";
import Empty from "./../components/Empty";
import { ReactComponent as ServiceIcon } from "./../icons/service-icon.svg";
import { ReactComponent as DashboardIcon } from "../icons/dashboard-icon.svg";
import { ReactComponent as MoreIcon } from "../icons/more-icon.svg";
import { ReactComponent as HelpIcon } from "../icons/help-icon.svg";
import Services from "./../components/services/Services";
import More from "./../components/more/More";
import MainTabSettingDropright from "./../components/MainTabSettingDropright";
import PlanningCenter from "./../components/dashboard/PlanningCenter";
import DashboardMain from "./../components/dashboard/DashboardMain";
import { isCordovaApp } from "../helpers/helpers";
import "../styles/components/main-tab.scss";

function TabContainer({ className, children, dir }) {
  return (
    <Typography
      component="div"
      dir={dir}
      className={`main-tab-content ${className}`}
    >
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

class Layout extends React.Component {
  constructor(props) {
    super(props);

    const values = this.props.location
      ? queryString.parse(this.props.location.search)
      : null;
    var value = 0;
    if (props.value) {
      value = parseInt(props.value);
    } else if (values && values.value) {
      value = parseInt(values.value);
    }

    const user = JSON.parse(localStorage.getItem("user"));
    //Todo multpile church
    this.state = {
      value,
      integrated:
        user &&
        user.success.user.churches[0].pivot.pco_access_token &&
        user.success.status !== "Not Active"
          ? true
          : false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    if (location?.search === "?1") {
      this.setState({ value: 0 });
    } else {
      this.setState({ value: 2 });
    }
  }

  handleChange(e, tabValue) {
    e.preventDefault();
    const { integrated } = this.state;
    const isCordova = isCordovaApp();
    const currentTab = !integrated ? tabValue + 1 : tabValue;
    if (currentTab === 3) {
      if (window.parent) {
        window.parent.postMessage(
          {
            event: "open-intercom",
          },
          "*"
        );
      }
      if (!isCordova) {
        var help2 = document.getElementById("intercom-container");
        if (help2) {
          if (help2.style.display === "block") {
            help2.style.display = "none";
          } else {
            help2.style.display = "block";
          }
        }
        var help = document.getElementsByClassName("intercom-lightweight-app");
        var i;
        for (i = 0; i < help.length; i++) {
          if (help[i].style.display === "block") {
            help[i].style.display = "none";
          } else {
            help[i].style.display = "block";
          }
        }
        // eslint-disable-next-line no-undef
        Intercom("show");
      }
    } else {
      this.setState({ value: tabValue });
    }
  }

  render() {
    const { value, integrated } = this.state;
    let impersonate = localStorage.getItem("impersonate");
    return (
      <div className={impersonate ? "impersonate main-tab" : "main-tab"}>
        <Tabs
          value={value}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          classes={{
            root: "container-wrapper",
            flexContainer: "flex-container",
            scroller: "scroller",
          }}
          TabIndicatorProps={{
            classes: {
              root: "tab-indicator",
            },
          }}
        >
          <Tooltip
            title="Dashboard"
            placement={window.innerWidth > 959 ? "right" : "top"}
          >
            <Tab
              component={Link}
              to="/dashboard"
              label={<span className="main-label">Dashboard</span>}
              icon={<DashboardIcon />}
              classes={{
                root: "single-tab dashboard",
                selected: "selected",
              }}
            />
          </Tooltip>
          {/*<Tooltip title="People" placement={window.innerWidth > 959 ? 'right' : 'top'}>
                <Tab
                label={<span className="main-label">People</span>}
                classes={{
                  root: 'single-tab',
                  selected: 'selected'
                }}
                />
                </Tooltip>*/}
          {integrated && (
            <Tooltip
              title="Services"
              placement={window.innerWidth > 959 ? "right" : "top"}
            >
              <Tab
                label={<span className="main-label">Services</span>}
                icon={<ServiceIcon />}
                classes={{
                  root: "single-tab service-tab",
                  selected: "selected",
                }}
              />
            </Tooltip>
          )}
          <Tooltip
            title="More"
            placement={window.innerWidth > 959 ? "right" : "top"}
          >
            <Tab
              label={<span className="main-label">More</span>}
              icon={<MoreIcon />}
              classes={{
                root: "single-tab more-tab",
                selected: "selected",
              }}
            />
          </Tooltip>
          <Tooltip title="Get Help" placement="top">
            <Tab
              label={<span className="main-label">Get Help</span>}
              icon={<HelpIcon />}
              classes={{
                root: "single-tab help-tab",
                selected: "selected",
              }}
            />
          </Tooltip>
          {/*
                    <Tab
                    classes={{
                      root: 'single-tab disabled',
                      selected: 'selected'
                    }}
                    />
                    <Tab
                    classes={{
                      root: 'single-tab disabled',
                      selected: 'selected'
                    }}
                    */}
          {/* /> */}
          <div className="bottom-icons">
            {/*<Tooltip title="Notifications" placement={window.innerWidth > 959 ? 'right' : 'top'}>
                <Tab
                label={<span className="main-label">Notifications</span>}
                icon={<NotificationIcon />}
                component={Link} to="/notifications"
                classes={{
                  root: 'single-tab notification',
                  selected: 'selected'
                }}
                />
                </Tooltip>*/}
            <MainTabSettingDropright />
          </div>
        </Tabs>
        {value === 0 && (
          <TabContainer className="dashboard-container">
            {window.location.pathname === "/authorization" ? (
              <PlanningCenter />
            ) : (
              <DashboardMain />
            )}
          </TabContainer>
        )}
        {/*value === 1 && false && <TabContainer><PeopleService /></TabContainer>*/}
        {integrated && value === 1 && (
          <TabContainer className="services">
            <Services />
          </TabContainer>
        )}
        {(value === 2 || (!integrated && value === 1)) && (
          <TabContainer className="main-tab-more">
            <More />
          </TabContainer>
        )}
        {value === 3 && (
          <TabContainer>
            <Empty />
          </TabContainer>
        )}
      </div>
    );
  }
}

export default withRouter(Layout);
