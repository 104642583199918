import React from "react";
import {
  Button,
  Dialog,
  Grid,
  Icon,
  IconButton,
  ListItem,
  Slide,
  Typography,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { authHeader } from "./../helpers/AuthHeader";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import { renderHTML } from "./../../helpers/helpers";
import "../../styles/components/more/billing.scss";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;
// var config = require("config");
var valid = require("card-validator");
const API_URL = process.env.REACT_APP_API_URL;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);

    var user = JSON.parse(localStorage.getItem("user"));
    var downgraded =
      (user.success.user.churches[0].plan_id.includes("pro") ||
        user.success.user.churches[0].plan_id.includes("free")) &&
      (this.props.item.description.includes("1") ||
        this.props.item.description.includes("3"));
    downgraded =
      downgraded ||
      (user.success.user.churches[0].plan_id.includes("business") &&
        this.props.item.description.includes("1"));

    this.state = {
      open: false,
      changePayment: false,
      paymentType: "card",
      cardNumber: "", //'4242424242424242',
      expiry: "", //'12/24',
      cvc: "", //'123',
      firstName: user.success.user.first_name,
      lastName: user.success.user.last_name,
      email: props.email ? props.email : user.success.user.email,
      address: "", //'Nepal',
      city: "", //'Butwal',
      state: "", //'Lumbini',
      zipcode: "", //'12345',
      country: "US",
      defaultPayment: true,
      packageId: this.props.item.id,
      isMonthly: this.props.period === "Monthly",
      defaultCard: this.props.defaultCard,
      coupon: null,
      loading: false,
      typingTimeout: 0,
      downgraded: downgraded,
      planId: user.success.user.churches[0].plan_id,
      status: user.success.status,
      error: {},
      flashMessage: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePay = this.handlePay.bind(this);
    this.handleFlash = this.handleFlash.bind(this);
  }

  handleFlash() {
    this.setState({ flashMessage: "" });
  }
  handleChange = (prop) => (event) => {
    var value = event.target.value;
    var name = prop;
    this.setState({
      [prop]:
        name === "expiry" && !isNaN(value) && value.length === 2
          ? value + "/"
          : value,
    });

    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    var _this = this;
    this.setState({
      typingTimeout: setTimeout(function () {
        var errors = _this.state.error;
        if (
          name === "cardNumber" &&
          value.length > 0 &&
          !valid.number(value).isValid
        ) {
          errors["cardNumber"] = "Invalid card number";
        } else if (name === "cardNumber") {
          errors["cardNumber"] = "";
        }

        var numberValidation = valid.number(_this.state.cardNumber);
        var cvvLength =
          numberValidation.card &&
          numberValidation.card.type === "american-express"
            ? 4
            : 3;
        if (
          name === "cvc" &&
          value.length > 0 &&
          !valid.cvv(value, cvvLength).isValid
        ) {
          errors["cvc"] = "Invalid cvc";
        } else if (name === "cvc") {
          errors["cvc"] = "";
        }

        if (
          name === "expiry" &&
          value.length > 0 &&
          !valid.expirationDate(value).isValid
        ) {
          errors["expiry"] = "Invalid expiry";
        } else if (name === "expiry") {
          errors["expiry"] = "";
        }

        _this.setState({ error: errors });
      }, 1000),
    });
  };

  handleShow = () => {
    this.setState({ changePayment: true });
  };

  handleHide = () => {
    this.setState({ changePayment: false });
  };

  handleClickOpen = () => {
    if (
      !this.props.currentPackage ||
      this.props.currentPackage.id !== this.props.item.id ||
      (this.props.currentPackage.id === this.props.item.id &&
        (this.props.period === "Monthly") !== this.props.isMonthly)
    ) {
      this.setState({ open: true });
    }
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = () => {
    this.setState({ error: {} });

    var numberValidation = valid.number(this.state.cardNumber);
    var cvvValidation = valid.cvv(
      this.state.cvc,
      numberValidation.card && numberValidation.card.type === "american-express"
        ? 4
        : 3
    );
    var expValidation = valid.expirationDate(this.state.expiry);

    if (
      !numberValidation.isValid ||
      !cvvValidation.isValid ||
      !expValidation.isValid
    ) {
      var errors = [];
      if (!numberValidation.isValid) {
        errors["cardNumber"] = "Invalid card number";
      }

      if (!cvvValidation.isValid) {
        errors["cvc"] = "Invalid cvc";
      }

      if (!expValidation.isValid) {
        errors["expiry"] = "Invalid expiry";
      }

      this.setState({ error: errors });
      return;
    }

    this.setState({ loading: true, flashMessage: "" });
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(this.state),
    };
    return fetch(`${API_URL}create-update-card`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          if (typeof data.error === "string") {
            this.setState({ error: { errorText: data.error } });
          } else {
            this.setState({ error: data.error });
          }
        } else {
          var message =
            "Card " +
            (this.state.defaultCard == null ? "added" : "changed") +
            " successfully. Click on PAY to complete the order.";
          this.setState({
            changePayment: false,
            defaultCard: data.success,
            flashMessage: message,
          });
          this.props.handler();
        }
        this.setState({ loading: false });
      })
      .catch(console.log);
  };

  handlePay = () => {
    this.setState({ error: {}, loading: true, flashMessage: "" });

    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(this.state),
    };
    return fetch(`${API_URL}subscribe`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          this.setState({ error: { errorChargeText: data.error } });
        } else {
          //location.href = '/billing?success=1';
          this.props.refreshAllSections(
            false,
            "You have successfully subscribed to a plan"
          );
          this.handleClose();
        }
        this.setState({ loading: false });
      })
      .catch(console.log);
  };

  getCoupon(code) {
    if (code) {
      this.setState({ loading: true });

      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ code: code }),
      };
      return fetch(`${API_URL}coupon`, requestOptions)
        .then((res) => {
          if (!res.ok) {
            if (res.status !== 401) return;
          }
          return res.json();
        })
        .then((data) => {
          this.setState({
            loading: false,
            coupon: data.coupon ? data.coupon : "invalid",
          });
        })
        .catch(console.log);
    } else {
      this.setState({ coupon: null });
    }
  }

  componentWillReceiveProps(props) {
    this.getCoupon(props.coupon);
    this.setState({ isMonthly: this.props.period === "Monthly" });
  }

  render() {
    const {
      cardNumber,
      expiry,
      cvc,
      firstName,
      lastName,
      email,
      address,
      city,
      state,
      zipcode,
      changePayment,
      open,
      defaultCard,
      coupon,
      downgraded,
      planId,
      status,
      loading,
      error,
      flashMessage,
    } = this.state;

    return (
      <React.Fragment>
        <div onClick={this.handleClickOpen} style={{ display: "contents" }}>
          {this.props.toggler}
        </div>
        <Dialog
          fullScreen
          open={open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          classes={{
            root: "subscription-plans-page order-details",
            paper: "paper",
          }}
          BackdropProps={{
            classes: {
              root: "backdrop",
            },
          }}
        >
          <div className={loading ? "sweet-loading" : ""}>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={150}
              color={"#7BC807"}
              loading={loading}
            />
          </div>

          <IconButton className="close-icon" onClick={this.handleClose}>
            <Icon>close</Icon>
          </IconButton>
          <Grid container className="content" spacing={2}>
            <Grid item className="left-content">
              <Typography className="page-title">Order details</Typography>
              {flashMessage !== "" && (
                <div className="flash-message mb">
                  <Typography>{flashMessage}</Typography>
                  <Icon
                    className="close-icon relative"
                    onClick={this.handleFlash}
                  >
                    close
                  </Icon>
                </div>
              )}
              <Grid container>
                <Grid item style={{ width: "15%" }}>
                  <div className="banner-image">
                    <img
                      src={
                        this.props.item.image
                          ? this.props.item.image
                          : "./images/starting-package.png"
                      }
                      alt=""
                    />
                  </div>
                </Grid>
                <Grid item style={{ width: "85%" }} className="offer-info">
                  {typeof error.errorChargeText === "string" && (
                    <div
                      className={"error alt alert-danger"}
                      dangerouslySetInnerHTML={{
                        __html: error.errorChargeText,
                      }}
                    />
                  )}
                  <Typography component="h5">Item</Typography>
                  <Typography component="h2" className="plan-title">
                    {this.props.item.name} / {this.props.period}
                  </Typography>
                  <Typography component="h2" className="price-tag">
                    $ {this.props.item.amount_per_month} / mo{" "}
                    <span className="small-text">
                      Paid Annually (${this.props.item.amount_per_year} / yr)
                    </span>
                  </Typography>
                  <div className="features">
                    <ListItem className="single">
                      {this.props.item.credit} credits per month
                    </ListItem>
                    <ListItem className="single">
                      <Tooltip
                        title={
                          this.props.item.tooltip
                            ? renderHTML(this.props.item.tooltip)
                            : ""
                        }
                      >
                        <div>{this.props.item.description}</div>
                      </Tooltip>
                    </ListItem>
                  </div>
                  <div className="coupon-applied">
                    {coupon && coupon !== "invalid" && (
                      <div className="coupon-title success">
                        <Typography component={"h5"}>Coupon applied</Typography>
                        <Typography component={"h5"}>
                          You'll be charged{" "}
                          {coupon.include_dollar ? "$1 now with" : "after"}{" "}
                          {coupon.trial_days} extended trial days.
                        </Typography>
                      </div>
                    )}
                    {coupon === "invalid" && (
                      <div className="coupon-title error">
                        <Typography component={"h5"}>
                          Invalid Coupon Code
                        </Typography>
                        <Typography component={"h5"}>
                          You can skip coupon or you can try again.
                        </Typography>
                      </div>
                    )}
                    <div className="after-coupon-amount">
                      <Typography component={"h5"}>
                        You will be charged
                      </Typography>
                      <Typography component={"h5"}>
                        {coupon && coupon !== "invalid" && coupon.include_dollar
                          ? "$1 for " + coupon.trial_days + " days then "
                          : ""}{" "}
                        $
                        {this.props.period === "Monthly"
                          ? this.props.item.amount_per_month
                          : this.props.item.amount_per_year}{" "}
                        per {this.props.period === "Monthly" ? "month" : "year"}
                      </Typography>
                    </div>
                  </div>

                  <div className="payment-method">
                    <div className="payment-title">
                      <Typography component={"h5"}>Payment method</Typography>
                    </div>
                    {defaultCard !== null && (
                      <div className="payment-detail">
                        <Typography component={"h5"}>
                          {defaultCard.brand} ending in {defaultCard.last4}{" "}
                        </Typography>
                        <Button
                          color="primary"
                          variant="text"
                          onClick={this.handleShow}
                          classes={{
                            root: "btn",
                            label: "label",
                            textPrimary: "text-primary",
                          }}
                        >
                          <span
                            className="forgot-pw"
                            style={{ color: "#7BC807" }}
                          >
                            Change
                          </span>
                        </Button>
                      </div>
                    )}
                    {defaultCard !== null && changePayment === false && (
                      <div className="btn-wrapper">
                        {downgraded && (
                          <Tooltip
                            title={
                              this.props.item.tooltip
                                ? renderHTML(this.props.item.tooltip)
                                : ""
                            }
                          >
                            <div className="alert-warning">
                              You are about to downgrade your plan. Instead of
                              access to&nbsp;
                              {planId.includes("pro") || status === "On Trial"
                                ? "5"
                                : "3"}
                              &nbsp;Schedule Templates (coming soon), you will
                              only have access to&nbsp;
                              {this.props.item.description
                                .replace("(limited options)", "")
                                .replace("(coming soon)", "")
                                .replace("schedule template", "")
                                .replace("schedule templates", "")}{" "}
                              with&nbsp;
                              {this.props.item.description.includes("1")
                                ? "limited"
                                : "unlimited"}{" "}
                              options. You will&nbsp;
                              {this.props.item.description.includes("1")
                                ? "not have edit access to any Message Template"
                                : "have edit access to some Message Templates"}
                              .
                            </div>
                          </Tooltip>
                        )}
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                          onClick={this.handlePay}
                          classes={{
                            root: "btn",
                            label: "label",
                            containedPrimary: "contained-primary",
                          }}
                        >
                          {downgraded ? "Pay and Downgrade" : "Pay"}
                        </Button>
                      </div>
                    )}
                    {defaultCard == null && (
                      <div className="payment-detail">
                        <Typography component={"h5"}>
                          please add a card before payment.
                        </Typography>
                        <Button
                          color="primary"
                          variant="text"
                          onClick={this.handleShow}
                          classes={{
                            root: "btn",
                            label: "label",
                            textPrimary: "text-primary",
                          }}
                        >
                          <span
                            className="forgot-pw"
                            style={{ color: "#7BC807" }}
                          >
                            Add
                          </span>
                        </Button>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="right-content">
              {changePayment === true ? (
                <div className="payment-method in-right">
                  {typeof error.errorText === "string" && (
                    <div
                      className={"error alt alert-danger"}
                      dangerouslySetInnerHTML={{ __html: error.errorText }}
                    />
                  )}
                  <div className="payment-title">
                    <Typography component={"h5"}>Payment method</Typography>
                  </div>
                  <div className="change-payment">
                    <Grid container spacing={2} className="new-card-detail">
                      {/*<Grid item style={{ width: '100%' }}>
                  <TextField
                      id="payment-type-select"
                      select
                      label="Select Card"
                      value={paymentType}
                      onChange={this.handleChange('paymentType')}
                      variant='filled'
                      fullWidth
                      classes={{
                        root: 'text-field',
                      }}
                      InputLabelProps={{
                        classes: {
                          root: 'label'
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: 'input-wrapper',
                          input: 'input'
                        }
                      }}
                      SelectProps={{
                         MenuProps: {
                          getContentAnchorEl: null,
                          anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left', }
                        }
                      }}
                  >
                        <MenuItem value='card'>Add new card</MenuItem>
                        <MenuItem value='paypal'>Add Paypal</MenuItem>
                        <MenuItem value='visa'>Add Visa card</MenuItem>
                    </TextField>
                    </Grid>*/}
                      <Grid item style={{ width: "40%" }}>
                        <TextField
                          id="card-number"
                          label="Credit Card Number"
                          fullWidth
                          variant="filled"
                          value={cardNumber}
                          onChange={this.handleChange("cardNumber")}
                          error={typeof error.cardNumber === "object"}
                          helperText={error.cardNumber ? error.cardNumber : ""}
                          classes={{
                            root: "text-field",
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "label",
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: "input-wrapper",
                              input: "input",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item style={{ width: "30%" }}>
                        <TextField
                          id="exp-date"
                          label="Exp.date (MM/YY)"
                          fullWidth
                          variant="filled"
                          value={expiry}
                          onChange={this.handleChange("expiry")}
                          error={typeof error.expiry === "object"}
                          helperText={error.expiry ? error.expiry : ""}
                          classes={{
                            root: "text-field",
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "label",
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: "input-wrapper",
                              input: "input",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item style={{ width: "30%" }}>
                        <TextField
                          id="cvc-code"
                          label="CVC CODE"
                          fullWidth
                          variant="filled"
                          value={cvc}
                          onChange={this.handleChange("cvc")}
                          error={typeof error.cvc === "object"}
                          helperText={error.cvc ? error.cvc : ""}
                          classes={{
                            root: "text-field",
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "label",
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: "input-wrapper",
                              input: "input",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item style={{ width: "50%" }}>
                        <TextField
                          id="first-name"
                          label="Cardholder Name"
                          value={firstName}
                          fullWidth
                          variant="filled"
                          onChange={this.handleChange("firstName")}
                          error={typeof error.firstName === "object"}
                          helperText={error.firstName ? error.firstName : ""}
                          classes={{
                            root: "text-field",
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "label",
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: "input-wrapper",
                              input: "input",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item style={{ width: "50%" }}>
                        <TextField
                          id="lastName"
                          label="Cardholder Last Name"
                          value={lastName}
                          fullWidth
                          variant="filled"
                          onChange={this.handleChange("lastName")}
                          error={typeof error.lastName === "object"}
                          helperText={error.lastName ? error.lastName : ""}
                          classes={{
                            root: "text-field",
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "label",
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: "input-wrapper",
                              input: "input",
                            },
                          }}
                        />
                      </Grid>
                      {/*<Grid item >
                    <Typography className="switch">
                      <CustomSwitch /> Set as default payment method
                    </Typography>
                  </Grid>*/}
                      <Grid item style={{ width: "100%" }}>
                        <TextField
                          id="email"
                          label="Email invoices to"
                          value={email}
                          fullWidth
                          variant="filled"
                          onChange={this.handleChange("email")}
                          error={typeof error.email === "object"}
                          helperText={error.email ? error.email : ""}
                          classes={{
                            root: "text-field",
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "label",
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: "input-wrapper",
                              input: "input",
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className="new-card-detail">
                      <Typography className="address-title">
                        Billing address
                      </Typography>
                      <Grid item style={{ width: "100%" }}>
                        <TextField
                          id="address"
                          label="Address"
                          value={address}
                          fullWidth
                          variant="filled"
                          onChange={this.handleChange("address")}
                          error={typeof error.address === "object"}
                          helperText={error.address ? error.address : ""}
                          classes={{
                            root: "text-field",
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "label",
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: "input-wrapper",
                              input: "input",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item style={{ width: "45%" }}>
                        <TextField
                          id="city"
                          label="City"
                          value={city}
                          fullWidth
                          variant="filled"
                          onChange={this.handleChange("city")}
                          error={typeof error.city === "object"}
                          helperText={error.city ? error.city : ""}
                          classes={{
                            root: "text-field",
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "label",
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: "input-wrapper",
                              input: "input",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item style={{ width: "30%" }}>
                        <TextField
                          id="state"
                          label="State"
                          value={state}
                          fullWidth
                          variant="filled"
                          onChange={this.handleChange("state")}
                          error={typeof error.state === "object"}
                          helperText={error.state ? error.state : ""}
                          classes={{
                            root: "text-field",
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "label",
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: "input-wrapper",
                              input: "input",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item style={{ width: "25%" }}>
                        <TextField
                          id="zip-code"
                          label="Zipcode"
                          value={zipcode}
                          fullWidth
                          variant="filled"
                          onChange={this.handleChange("zipcode")}
                          error={typeof error.zipcode === "object"}
                          helperText={error.zipcode ? error.zipcode : ""}
                          classes={{
                            root: "text-field",
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "label",
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: "input-wrapper",
                              input: "input",
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <div className="action-buttons">
                      <Button
                        color="primary"
                        variant="text"
                        onClick={this.handleHide}
                        classes={{
                          root: "btn",
                          label: "label",
                          textPrimary: "text-primary",
                        }}
                      >
                        <span
                          className="forgot-pw"
                          style={{ color: "#7BC807" }}
                        >
                          Cancel
                        </span>
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={this.handleSubmit}
                        classes={{
                          root: "btn",
                          label: "label",
                          containedPrimary: "contained-primary",
                        }}
                      >
                        Change
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {/*<TweetEmbed className="tweet-embed" id="1249382299898204160" />*/}
                  <img src="./images/payment-cards.png" alt="" />
                </div>
              )}
            </Grid>
          </Grid>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default OrderDetails;
