import React from "react";
import { geolocated } from "react-geolocated";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Typography,
  Button,
  Card,
  Grid,
  TextField,
  MenuItem,
  Icon,
  Radio,
  RadioGroup,
  FormControlLabel,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Fab,
  Paper,
  IconButton,
  Menu,
} from "@material-ui/core";
import HeaderMore from "./HeaderMore";
import AddNewNumberStepOne from "./AddNewNumberStepOne";
import SuggestedNumbers from "./../SuggestedNumbers";
import { userService } from "./../services/UserService";
import { twilioService } from "./../services/TwilioService";
import PagesSubMenu from "./PagesSubMenu";
import { authHeader } from "./../helpers/AuthHeader";
import { formatPhoneNumber } from "../../helpers/helpers";
import userProfileAction from "../../store/actions/userProfileAction";
import "../../styles/components/more/number.scss";

const API_URL = process.env.REACT_APP_API_URL;
class Number extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phone_number: "",
      phone_numbers: [],
      display_phone_number: "",
      country: "US",
      selectBy: "location",
      partOfNum: "first",
      numberType: "local",
      search: "",
      submitted: false,
      loading: false,
      numbers: [],
      showNumberForm: false,
      locationNumber: "",
      locationNumberDisplay: "",
      countries: [
        { iso: "US", name: "United State" },
        { iso: "CA", name: "Canada" },
        { iso: "GB", name: "United Kingdom" },
      ],
      error: {},
      anchorEl: null,
      numberShown: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickMenu = this.handleClickMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.switchPrimary = this.switchPrimary.bind(this);
    this.removeNumber = this.removeNumber.bind(this);
    this.reset = this.reset.bind(this);
  }

  handleClickMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  componentDidMount() {
    this.reset();
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    if (name !== "phone_number" && name !== "search") {
      //setTimeout(function(){_this.refreshNumbers()}, 100);
    } else if (name === "phone_number") {
      this.setState({
        display_phone_number:
          value === "NZ" ? "New Zealand (one-way)" : formatPhoneNumber(value),
      });
    }
  }

  handleClick(e) {
    this.refreshNumbers();
  }

  switchPrimary() {
    this.setState({ loading: true });
    var value = this.state.anchorEl.getAttribute("number");

    userService.switchNumber(value).then((data) => {
      this.reset().then((data) => {
        this.setState({ loading: false });
      });
    });
  }

  removeNumber() {
    this.setState({ loading: true });
    var value = this.state.anchorEl.getAttribute("id");

    userService.removeNumber(value).then((data) => {
      this.reset().then((data) => {
        this.setState({ loading: false });
      });
    });
  }

  showNumberFormAction() {
    this.setState({ showNumberForm: true });
  }

  refreshNumbers() {
    var { country, selectBy, search, partOfNum, numberType } = this.state;

    // stop here if form is invalid
    if (!(country && selectBy && partOfNum && numberType)) {
      return;
    }

    this.setState({ loading: true });
    if (search.length === 0) {
      selectBy = "latlong";
      search =
        this.props.isGeolocationAvailable &&
        this.props.isGeolocationEnabled &&
        this.props.coords
          ? this.props.coords.latitude + "," + this.props.coords.longitude
          : "";
      //search = "37.840699,-122.461853"
      console.log(search);
    }

    twilioService
      .numbers(country, selectBy, search, partOfNum, numberType)
      .then(
        (data) => {
          //console.log(data);
          if (data) {
            this.setState({ numbers: data, loading: false });
          }
        },
        (error) => {
          console.log(error);
          this.setState({ loading: false });
        }
      );
  }

  handleSubmit(e) {
    e.preventDefault();
    const { phone_number, country } = this.state;
    userService.saveNumber(phone_number, country).then(
      (data) => {
        this.setState({ loading: false, showNumberForm: false, numbers: [] });
        this.reset();
      },
      (error) => {
        console.log(error);
        this.setState({ loading: false, showNumberForm: false });
      }
    );
  }

  searchByLatLong(country, partOfNum, numberType) {
    const selectBy1 = "latlong";
    var search1 =
      this.props.isGeolocationAvailable &&
      this.props.isGeolocationEnabled &&
      this.props.coords
        ? this.props.coords.latitude + "," + this.props.coords.longitude
        : "";
    //var search1 = "37.840699,-122.461853";
    console.log(search1);
    if (search1.length > 0) {
      this.setState({ numberShown: true });
      twilioService
        .numbers(country, selectBy1, search1, partOfNum, numberType)
        .then(
          (data) => {
            if (data.length > 0) {
              this.setState({ locationNumber: data[0]["value"] });
              this.setState({ locationNumberDisplay: data[0]["number"] });
            }
          },
          (error) => {
            console.log(error);
            this.setState({ loading: false });
          }
        );
    }
  }

  reset() {
    const { updateUserProfileData } = this.props;
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    this.setState({ loading: true, anchorEl: null });
    return fetch(`${API_URL}profile`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        updateUserProfileData(data);
        this.setState({
          phone_numbers: data.phone_numbers,
          phone_number: data.phone_number ? data.phone_number.number : "",
          display_phone_number: formatPhoneNumber(data?.phone_number?.number),
          loading: false,
        });
      })
      .catch(console.log);
  }

  render() {
    const {
      country,
      selectBy,
      partOfNum,
      search,
      anchorEl,
      phone_numbers,
      display_phone_number,
      phone_number,
      numberType,
      numbers,
      countries,
      error,
      showNumberForm,
      locationNumber,
      locationNumberDisplay,
      numberShown,
    } = this.state;

    if (search.length === 0 && !numberShown) {
      //console.log(search.length);
      this.searchByLatLong(country, partOfNum, numberType);
    }
    const activeNumbers = phone_numbers.filter((item) => item.active === 1);
    const hasPlNumber = phone_numbers.some(
      (num) => num.active === 1 && num.is_pl === 1
    );
    const hasActiveNormalNum = phone_numbers.some(
      (num) => num.active === 1 && num.is_pl === 0
    );
    return (
      <div className="page-wrapper with-scroll numbers-page">
        <PagesSubMenu />
        <div className="desktop-view">
          <div className="title-section">
            <Typography className="desktop-title" component={"h2"}>
              My Numbers
            </Typography>
            {activeNumbers?.length < 2 && (
              <AddNewNumberStepOne
                country={country}
                number={locationNumberDisplay}
                phone_number={locationNumber}
                reset={this.reset}
                hasPlNumber={hasPlNumber}
                hasActiveNormalNum={hasActiveNormalNum}
              />
            )}
          </div>
          <TableContainer component={Paper} className="number-page-desktop">
            <Table stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow className="table-head">
                  <TableCell>Number</TableCell>
                  <TableCell align="left">App</TableCell>
                  <TableCell
                    style={{ backgroundColor: "#fafafa" }}
                    align="right"
                  />
                </TableRow>
              </TableHead>
              <TableBody className="table-body-for-numbers">
                {phone_numbers.map((row, idx) => (
                  <TableRow
                    className={row.active === 1 ? "" : "hidden"}
                    key={idx}
                  >
                    <TableCell scope="row">
                      {" "}
                      {formatPhoneNumber(row.number, row.country)}{" "}
                    </TableCell>
                    <TableCell className="app-column" align="left">
                      {row.is_pl ? "Pastorsline" : "Confirmed.Church"}{" "}
                      {row.primary ? (
                        <span className="default">Default</span>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {row.primary === 0 && (
                        <div>
                          <IconButton
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            number={row.number}
                            is_pl={row.is_pl}
                            id={row.id}
                            onClick={this.handleClickMenu}
                          >
                            <Icon>more_vert</Icon>
                          </IconButton>
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={this.handleClose}
                            classes={{
                              paper: "dropdown-more",
                            }}
                          >
                            <MenuItem onClick={this.switchPrimary}>
                              Make default
                            </MenuItem>
                            <MenuItem onClick={this.removeNumber}>
                              {anchorEl && anchorEl.is_pl
                                ? "Remove"
                                : "Release"}
                            </MenuItem>
                          </Menu>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="mobile-view">
          {showNumberForm && (
            <HeaderMore
              backView="/dashboard"
              title="Number"
              nextView="#"
              actionButton="Save"
              handleAction={this.handleSubmit}
            />
          )}
          {!showNumberForm && (
            <HeaderMore backView="/dashboard" title="Number" nextView="#" />
          )}
          <div className="choose-number-wrapper">
            {error.errorText !== "" && (
              <div
                className={"alt alert-danger"}
                dangerouslySetInnerHTML={{ __html: error.errorText }}
              />
            )}
            {!showNumberForm && (
              <div>
                {phone_numbers.map((row, idx) => (
                  <Card
                    className={
                      row.active === 1 ? "card-single" : "card-single hidden"
                    }
                    key={idx}
                  >
                    <div className="top-section">
                      <Typography className="number">
                        {" "}
                        {formatPhoneNumber(row.number, row.country)}{" "}
                      </Typography>
                      {row.primary === 0 && (
                        <React.Fragment>
                          <IconButton
                            className="icon-button"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            number={row.number}
                            is_pl={row.is_pl}
                            id={row.id}
                            onClick={this.handleClickMenu}
                          >
                            <Icon>more_vert</Icon>
                          </IconButton>
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={this.handleClose}
                            classes={{
                              paper: "dropdown-more",
                            }}
                          >
                            <MenuItem onClick={this.switchPrimary}>
                              Make default
                            </MenuItem>
                            <MenuItem onClick={this.removeNumber}>
                              {anchorEl && anchorEl.is_pl
                                ? "Remove"
                                : "Release"}
                            </MenuItem>
                          </Menu>
                        </React.Fragment>
                      )}
                    </div>
                    <div className="main-section">
                      <Typography>
                        {row.is_pl ? "Pastorsline" : "Confirmed.Church"}
                      </Typography>
                      {row.primary === 1 && (
                        <Typography className="default-tag">Default</Typography>
                      )}
                    </div>
                  </Card>
                ))}
                {activeNumbers?.length < 2 && (
                  <Fab
                    color="primary"
                    aria-label="Add"
                    className="fab"
                    component={Link}
                    onClick={() => this.showNumberFormAction()}
                    to="#"
                  >
                    <Icon>add</Icon>
                  </Fab>
                )}
              </div>
            )}
            {showNumberForm && (
              <div>
                <div className="selected-number">
                  {phone_number === "NZ"
                    ? "New Zealand (one-way)"
                    : display_phone_number}
                </div>
                <Typography className="title" component="h4">
                  Would you like to change your number?
                </Typography>
                {!hasActiveNormalNum && (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        id="filled-uncontrolled"
                        select
                        label="Country"
                        margin="normal"
                        variant="filled"
                        name="country"
                        value={country}
                        onChange={this.handleChange}
                        classes={{
                          root: "text-field",
                        }}
                        InputLabelProps={{
                          classes: {
                            root: "label",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: "input-wrapper",
                            input: "input",
                          },
                        }}
                        SelectProps={{
                          MenuProps: {
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                          },
                        }}
                      >
                        {countries.map((country, idx) => (
                          <MenuItem value={country.iso} key={idx}>
                            {" "}
                            {country.name}{" "}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="filled-uncontrolled"
                        select
                        label="By"
                        margin="normal"
                        variant="filled"
                        name="selectBy"
                        value={selectBy}
                        onChange={this.handleChange}
                        classes={{
                          root: "text-field",
                        }}
                        InputLabelProps={{
                          classes: {
                            root: "label",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: "input-wrapper",
                            input: "input",
                          },
                        }}
                        SelectProps={{
                          MenuProps: {
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                          },
                        }}
                      >
                        <MenuItem value="location"> Location </MenuItem>
                        <MenuItem value="number"> Number </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="filled-uncontrolled"
                        placeholder="Search by digits, phrases or leave blank"
                        margin="normal"
                        variant="filled"
                        name="search"
                        value={search}
                        onChange={this.handleChange}
                        classes={{
                          root: "text-field",
                        }}
                        InputLabelProps={{
                          classes: {
                            root: "label",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: "input-wrapper",
                            input: "input",
                          },
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            this.refreshNumbers();
                          }
                        }}
                        error={typeof error.phone === "string"}
                        helperText={error.phone ? error.phone : ""}
                        FormHelperTextProps={{
                          classes: {
                            root: "helper-text",
                          },
                        }}
                      />
                    </Grid>
                    {selectBy === "number" && (
                      <Grid item xs={12}>
                        <TextField
                          id="filled-uncontrolled"
                          select
                          label="Match to"
                          margin="normal"
                          variant="filled"
                          name="partOfNum"
                          value={partOfNum}
                          onChange={this.handleChange}
                          classes={{
                            root: "text-field",
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "label",
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: "input-wrapper",
                              input: "input",
                            },
                          }}
                          SelectProps={{
                            MenuProps: {
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                            },
                          }}
                        >
                          <MenuItem value="first">
                            {" "}
                            First Part of Number{" "}
                          </MenuItem>
                          <MenuItem value="anywhere">
                            {" "}
                            Anywhere in Number{" "}
                          </MenuItem>
                          <MenuItem value="last">
                            {" "}
                            Last Part of Number{" "}
                          </MenuItem>
                        </TextField>
                      </Grid>
                    )}
                    <RadioGroup
                      className="radio-group"
                      aria-label="radios"
                      name="numberType"
                      value={numberType}
                      onChange={this.handleChange}
                    >
                      <Grid item xs={12} className="radio-buttons">
                        <FormControlLabel
                          value="local"
                          control={<Radio color="primary" />}
                          label="Local"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="tollFree"
                          control={<Radio color="primary" />}
                          label="Toll free"
                          labelPlacement="end"
                        />
                      </Grid>
                    </RadioGroup>
                    <Grid item xs={12}>
                      <SuggestedNumbers
                        numbers={numbers}
                        handleChange={this.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {numbers.length > 0 ? (
                        <Button
                          color="primary"
                          variant="text"
                          onClick={this.handleClick}
                          classes={{
                            root: "btn",
                            label: "label",
                            textPrimary: "text-primary",
                          }}
                        >
                          <Icon className="reload">cached</Icon>
                          Reload
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          variant="text"
                          onClick={this.handleClick}
                          classes={{
                            root: "btn",
                            label: "label",
                            textPrimary: "text-primary",
                          }}
                        >
                          <Icon className="search">search</Icon>
                          Search
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                )}
                {!hasPlNumber && (
                  <div>
                    {!hasPlNumber && !hasActiveNormalNum && (
                      <Typography className="title" component="h4">
                        Or use your pastorsline number
                      </Typography>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      component={Link}
                      to="/pastorsline"
                      classes={{
                        root: "pastorsline-btn",
                        label: "label",
                        containedPrimary: "contained-primary",
                      }}
                    >
                      <img src="./images/pastorsline.svg" alt="" />
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUserProfileData: (updatedData) =>
    dispatch(userProfileAction.getUserProfileDataSuccess(updatedData)),
});

export default connect(
  null,
  mapDispatchToProps
)(
  geolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  })(Number)
);
