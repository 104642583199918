import React from "react";
import Swipeout from "rc-swipeout";
import queryString from "query-string";
import { connect } from "react-redux";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Switch,
  Hidden,
  Tooltip,
  Icon,
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import HeaderMore from "./HeaderMore";
import PagesSubMenu from "./PagesSubMenu";
import { authHeader } from "./../helpers/AuthHeader";
import { ReactComponent as ConfirmationIcon } from "../../icons/confirmation-icon.svg";
import { ReactComponent as ReminderIcon } from "../../icons/reminder-icon.svg";
import { getAllScheduleTemplate, getScheduleTemplate, getSchedulingServices } from "../../services/api";
import { isEmptyObject } from "../../helpers/helpers";
import AssignTemplateModal from "../Modals/AssignTemplateModal";
import { clearAssignTemplateStatus } from "../../store/actions/serviceAction";
import "../../styles/components/more/schedule-templates.scss";
import { getSchedulingServicesMiddleware } from "../../store/middlewares/servicesMiddleware";
import ButtonWithSpinner from "../HelperComponents/ButtonWithSpinner";

// var config = require("config");
const API_URL = process.env.REACT_APP_API_URL;

const startingPlan = "starting";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;
class EditSchedule extends React.Component {
  constructor(props) {
    super(props);
    const values = queryString.parse(this.props?.location?.search);
    const currentUser = JSON.parse(localStorage.getItem("user")).success.user;
    this.state = {
      scheduleTemplate: values?.id ? values.id : 0,
      followUp: false,
      //confirmReminder: false,
      showDays: true,
      confirmation: "", //"ASAP",
      confirmationReminders: [], //"Send a confirmation request reminder if unconfirmed 1 week from the event.",
      templateName: "",
      timezone: "",
      followUps: [],
      defaults: [],
      combination: null,
      locked: currentUser.churches[0].plan_id.includes(startingPlan),
      initialConfirmationSwitch:
        currentUser.churches[0].initial_confirmation_switch,
      loading: false,
      errorMessage: "",
      hoverAction: "",
      editAction: "",
      confirmationRemindersText: "",
      followUpText: "",
      scheduleTemplateToAssignFolders: {},
      currentTemplate: {},
      snackbarData: {},
      allServices: [],
      folderList: {},
      assignFoldersLoading: true,
    };
  }

  async componentDidMount() {
    const allServices = await getSchedulingServices("");
    this.setState({ allServices: allServices });
    if (this.props.onConnectPcoModal) {
      const templates = await getAllScheduleTemplate();
      if (templates?.length) {
        this.setState({scheduleTemplate: templates.find(template => template.is_default)?.id})
      }
    }
    this.refreshSchedules();
  }

  componentDidUpdate(prevProps, prevState) {
    const { assignTemplateStatus, clearAssignTemplateStatus } = this.props;
    if (prevProps.assignTemplateStatus !== assignTemplateStatus) {
      if (assignTemplateStatus === "success") {
        clearAssignTemplateStatus();
        this.setState({
          snackbarData: {
            showSnackBar: true,
            snackbarMessage: "Folders and services assigned successfully",
            error: false,
          },
        });
      } else if (assignTemplateStatus === "error") {
        clearAssignTemplateStatus();
        this.setState({
          snackbarData: {
            showSnackBar: true,
            snackbarMessage: "Error assigning folder and service",
            error: true,
          },
        });
      }
    }
    if (
      prevState.scheduleTemplateToAssignFolders !==
      this.state.scheduleTemplateToAssignFolders
    ) {
      this.setState({ assignFoldersLoading: true });
      this.refreshSchedules();
    }
  }

  setFollowUpsText = (allFollowUps = []) => {
    const activeOptions = this.getActiveOptions(allFollowUps);
    if (activeOptions.length) {
      const followUps = this.createTextForTemplate(activeOptions, "follow-up ");
      this.setState({
        followUpText: `(${followUps.join(" / ")}) before the event`,
      });
    } else {
      this.setState({ followUpText: "No follow-up selected" });
    }
  };

  setConfirmationReminderText = (allConfirmationReminders = []) => {
    const activeOptions = this.getActiveOptions(allConfirmationReminders);
    if (activeOptions.length) {
      const reminders = this.createTextForTemplate(
        activeOptions,
        "confirmation reminder "
      );
      this.setState({
        confirmationRemindersText: `if unconfirmed (${reminders.join(
          " / "
        )}) before the event`,
      });
    } else {
      this.setState({
        confirmationRemindersText: "No confirmation reminders selected",
      });
    }
  };

  createTextForTemplate = (stringsArray, textForSliceStr) => {
    let text = [];
    stringsArray.forEach((item) => {
      const reminder = item.slice(
        textForSliceStr.length,
        item.indexOf("before")
      );
      text.push(reminder?.trim());
    });
    return text;
  };

  getActiveOptions = (data) => {
    const filterActiveItems = data
      .filter((item) => Boolean(item.is_default))
      .map((el) => el.schedules);
    return filterActiveItems;
  };

  handleSwitchInitial() {
    var url = `${API_URL}schedule-templates/switch-initial`;
    this.setState({ loading: true });
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({ active: false }),
    };
    return fetch(url, requestOptions)
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        let user = JSON.parse(localStorage.getItem("user"));
        user.success.user.churches[0].initial_confirmation_switch =
          data.initial;
        localStorage.setItem("user", JSON.stringify(user));
        this.setState({
          initialConfirmationSwitch: data.initial,
          loading: false,
        });
      })
      .catch(console.log);
  }

  handleSwitchConfirmation(id) {
    var url = `${API_URL}schedule-templates/switch-followup`;
    if (id) {
      url += "/" + id;
    }
    this.setState({ loading: true });
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({ active: false }),
    };
    return fetch(url, requestOptions)
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        this.setState(
          {
            confirmationReminders: data.confirmationReminders,
            loading: false,
          },
          () => {
            this.setConfirmationReminderText(data.confirmationReminders);
          }
        );
      })
      .catch(console.log);
  }

  handleSwitch(id) {
    //console.log(id);
    const { followUp } = this.state;
    var url = `${API_URL}schedule-templates/switch-followup`;
    if (id) {
      url += "/" + id;
    }
    this.setState({ loading: true });
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({ active: !followUp }),
    };
    return fetch(url, requestOptions)
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        const followUp = data?.followUps?.some((item) =>
          Boolean(item.is_default)
        );
        this.setState(
          {
            followUps: data.followUps,
            combination: data.combination,
            loading: false,
            followUp,
          },
          () => {
            this.setFollowUpsText(data.followUps);
          }
        );
      })
      .catch(console.log);
  }

  handleShowDays = (e) => {
    this.setState({ showDays: true });
  };

  refreshSchedules = async () => {
    const { scheduleTemplate } = this.state;
    try {
      const data = await getScheduleTemplate(scheduleTemplate);
      const followUp = data?.template?.follow_ups?.some(
        (item) => item.is_default === 1
      );
      this.setState({
        confirmation: "8AM - 9PM", //data.template.schedules.replace("confirmation ", "") + " 8AM - 9PM ",
        templateName: data.template?.name,
        followUps: data.template?.follow_ups,
        confirmationReminders: data.template?.confirmation_reminders,
        timezone: " (" + data.timezone + ")",
        defaults: data.defaults,
        combination: data.template?.combination,
        locked: data.locked,
        showDays: true,
        loading: false,
        followUp,
        currentTemplate: data,
        folderList: data?.folderList,
        serviceList: data?.serviceList,
        assignFoldersLoading: false,
      });
      this.setConfirmationReminderText(data.template?.confirmation_reminders);
      this.setFollowUpsText(data.template?.follow_ups);
    } catch (error) {
      this.setState({ errorMessage: error?.message });
      this.setState({ assignFoldersLoading: false });
    }
  };

  closeConfirmationModal = () => {
    this.setState({ editAction: "" });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarData: {} });
  };

  setScheduleTemplateToAssignFolders =
    (scheduleTemplateToAssignFolders = {}) =>
    () => {
      this.setState({ scheduleTemplateToAssignFolders });
    };

  getServiceTitle = (serviceIds) => {
    const serviceTitles = [];
    if (serviceIds && this.state.allServices) {
      serviceIds?.map((serviceId) => {
        serviceTitles.push(
          this.state.allServices?.find((service) => service.id === serviceId)
            ?.title
        );
        return true;
      });
    }
    return serviceTitles?.join(", ");
  };

  render() {
    const {
      followUp,
      showDays,
      confirmation,
      templateName,
      timezone,
      followUps,
      confirmationReminders,
      defaults,
      combination,
      locked,
      initialConfirmationSwitch,
      currentTemplate,
      scheduleTemplateToAssignFolders,
      snackbarData,
      folderList,
      serviceList,
      assignFoldersLoading,
    } = this.state;
    const { onConnectPcoModal } = this.props;
    let pageContainerClassNme = "";
    if (!onConnectPcoModal) {
      if (window.innerWidth > 959) {
        pageContainerClassNme = "desktop-design";
      } else {
        pageContainerClassNme = "inner-content-wrapper";
      }
    }

    let header = "Schedule Templates";
    if (templateName) {
      header = `${header} > ${templateName}`;
    }

    return (
      <>
        <div
          className={`edit-schedule page-wrapper my-profile ${
            onConnectPcoModal ? "w-100 ml-0" : ""
          }`}
        >
          {!onConnectPcoModal && (
            <>
              <PagesSubMenu />
              <Hidden mdUp>
                <HeaderMore
                  backView="/schedule-templates"
                  title={`...> ${templateName}`}
                  nextView="/dashboard"
                />
              </Hidden>
            </>
          )}
          <div className={pageContainerClassNme}>
            {!serviceList && !assignFoldersLoading ? (
              <div className={"alt alert-danger mb-2"}>
                Messages will be sent to all available services and folders by
                default.
              </div>
            ) : null}
            {!onConnectPcoModal && (
              <>
                <Hidden smDown>
                  <Typography className="desktop-title" component={"h2"}>
                    {header}{" "}
                    <ButtonWithSpinner
                      classes={{
                        root: "btn ml-2 assign-template-btn",
                        containedPrimary: "contained-primary",
                      }}
                      onClick={this.setScheduleTemplateToAssignFolders(
                        currentTemplate
                      )}
                      disabled={assignFoldersLoading}
                      loading={assignFoldersLoading}
                    >
                      {`${
                        folderList && Object.keys(folderList)?.length
                          ? "Update"
                          : "Assign"
                      } this template to folders and services`}
                    </ButtonWithSpinner>
                  </Typography>
                </Hidden>
              </>
            )}
            <div className="content">
              <Typography className="switches-title">
                Assigned Folders and Services:{" "}
              {onConnectPcoModal && (
              <>
                <Hidden smDown>
                    <ButtonWithSpinner
                      classes={{
                        root: "btn ml-2 mt-0 assign-template-btn",
                        containedPrimary: "contained-primary",
                      }}
                      onClick={this.setScheduleTemplateToAssignFolders(
                        currentTemplate
                      )}
                      disabled={assignFoldersLoading}
                      loading={assignFoldersLoading}
                    >
                      {`${
                        folderList && Object.keys(folderList)?.length
                          ? "Update"
                          : "Assign"
                      } this template to folders and services`}
                    </ButtonWithSpinner>
                </Hidden>
              </>
            )}
              </Typography>
              <ClipLoader
                css={override}
                sizeUnit={"px"}
                size={90}
                color={"#7BC807"}
                loading={assignFoldersLoading}
              />
              {!assignFoldersLoading && (
                <TableContainer className="table-container mb-5">
                  <Table aria-label="simple table" className="cards-table">
                    <TableHead className="table-head">
                      <TableRow>
                        <TableCell className="font-weight-bold">
                          Folders
                        </TableCell>
                        <TableCell className="font-weight-bold">
                          Services
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {folderList && Object.keys(folderList)?.length ? (
                        Object.keys(folderList)?.map((folder, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{folder}</TableCell>
                              <TableCell>
                                {this.getServiceTitle(serviceList[folder])}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow className="text-center">
                          No assigned folders and services found!
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
            <div className={`content ${onConnectPcoModal ? "w-100" : ""}`}>
              {timezone && (
                <>
                  <Typography
                    className={`switches-title ${
                      onConnectPcoModal ? "p-0" : ""
                    }`}
                  >
                    Scheduling Timezone:{" "}
                  </Typography>
                  <Typography
                    component="h6"
                    className="mt-2 ml-4"
                    style={{ fontSize: 14 }}
                  >
                    {confirmation} {timezone}
                  </Typography>
                </>
              )}

              <div className={onConnectPcoModal ? "" : "mt-5"}>
                <Typography
                  component="h3"
                  className={`switches-title ${onConnectPcoModal ? "p-0" : ""}`}
                >
                  <ReminderIcon className="mr-2" /> Send a confirmation
                  (accept/decline) intial confirmation text
                </Typography>
                <div className="switch-control-two">
                  <Tooltip title="Send an initial text confirmation request as soon as the system finds an unconfirmed PCO service request">
                    <Typography component="h3">
                      Send an initial text confirmation request as soon as the
                      system finds an unconfirmed PCO service request
                    </Typography>
                  </Tooltip>
                  <Tooltip
                    title={
                      "Send an initial text confirmation request as soon as the system finds an unconfirmed PCO service request"
                    }
                  >
                    <div>
                      <Switch
                        name={
                          "Send an initial text confirmation request as soon as the system finds an unconfirmed PCO service request"
                        }
                        //onChange={this.handleChange}
                        checked={initialConfirmationSwitch}
                        onChange={() => this.handleSwitchInitial()}
                        value={initialConfirmationSwitch}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        disableRipple
                        classes={{
                          root: "custom-switch",
                          thumb: "thumb",
                          track: "track",
                          switchBase: "switch-base",
                          checked: "checked",
                        }}
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>

              <Typography
                component="h3"
                className={`switches-title mt-5 ${
                  onConnectPcoModal ? "p-0" : ""
                }`}
              >
                <ConfirmationIcon className="mr-2" /> Send a confirmation
                (accept/decline) text reminder
              </Typography>
              <Typography
                component="h6"
                className="mt-2 ml-4"
                style={{ fontSize: 14 }}
              >
                {confirmationReminders &&
                Array.isArray(confirmationReminders) &&
                Boolean(confirmationReminders.length) ? (
                  confirmationReminders.map((item, i) => (
                    <div
                      className={
                        confirmationReminders.length === i + 1
                          ? "switch-control reminder d-flex justify-content-between align-item-center"
                          : "switch-control d-flex justify-content-between align-item-center"
                      }
                      key={i}
                    >
                      <Typography component="h3">
                        {item.name?.slice(36, item.name?.length)}
                      </Typography>
                      <Tooltip
                        title={
                          locked && !defaults[item.schedules]
                            ? "Not available in this plan"
                            : ""
                        }
                      >
                        <div>
                          {locked && !defaults[item.schedules] && (
                            <Icon>lock</Icon>
                          )}
                          <Switch
                            name="confirmReminder"
                            //onChange={this.handleChange}
                            checked={item.is_default === 1}
                            disabled={locked && !defaults[item.schedules]}
                            onChange={() =>
                              this.handleSwitchConfirmation(item.id)
                            }
                            value={item.is_default}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            disableRipple
                            classes={{
                              root: "custom-switch",
                              thumb: "thumb",
                              track: "track",
                              switchBase: "switch-base",
                              checked: "checked",
                            }}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  ))
                ) : (
                  <Typography className="text-start">
                    No confirmation reminders selected
                  </Typography>
                )}
              </Typography>

              <div
                className={`switch-control mt-5 ${
                  onConnectPcoModal ? "p-0" : ""
                }`}
              >
                <Typography className="sub-title text-start">
                  <ReminderIcon className="mr-2" /> After the person confirms,
                  send these follow-up text reminders:
                </Typography>

                <Switch
                  checked={followUp}
                  name="followUp"
                  onChange={() => this.handleSwitch()}
                  value={followUp}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  disableRipple
                  classes={{
                    root: "custom-switch",
                    thumb: "thumb",
                    track: "track",
                    switchBase: "switch-base",
                    checked: "checked",
                  }}
                />
              </div>
              <Typography
                component="h6"
                className="mt-2 ml-4"
                style={{ fontSize: 14 }}
              >
                {followUp && (
                  <div>
                    {showDays ? (
                      <List
                        component="nav"
                        aria-label="Secondary mailbox folders"
                        className="schedule-list"
                      >
                        {followUps.map((item, idx) => (
                          <Swipeout
                            left={[
                              {
                                text: "Delete",
                                onPress: () => console.log("delete"),
                                className: "swipeout-left",
                              },
                            ]}
                            right={[
                              {
                                text: "Edit",
                                onPress: () => console.log("delete"),
                                className: "swipeout-right",
                              },
                            ]}
                            onOpen={() => console.log("open")}
                            onClose={() => console.log("close")}
                            key={idx}
                          >
                            <Tooltip
                              title={
                                locked && !defaults[item.schedules]
                                  ? "Not available in this plan"
                                  : ""
                              }
                            >
                              <div className="switch-right">
                                {locked && !defaults[item.schedules] && (
                                  <Icon>lock</Icon>
                                )}
                                <Switch
                                  checked={item.is_default === 1}
                                  disabled={locked && !defaults[item.schedules]}
                                  name={"f_" + item.id}
                                  onChange={() => this.handleSwitch(item.id)}
                                  value={item.is_default}
                                  inputProps={{
                                    "aria-label": "secondary checkbox",
                                  }}
                                  disableRipple
                                  classes={{
                                    root: "custom-switch",
                                    thumb: "thumb",
                                    track: "track",
                                    switchBase: "switch-base",
                                    checked: "checked",
                                  }}
                                />
                              </div>
                            </Tooltip>
                            <ListItem button className="single-listitem">
                              <ListItemText
                                className="label"
                                primary={item.name}
                              />
                            </ListItem>
                          </Swipeout>
                        ))}
                      </List>
                    ) : (
                      <div className="switch-on">
                        <Button
                          onClick={this.handleShowDays}
                          variant="outlined"
                          className="btn add-followup"
                        >
                          Add follow up
                        </Button>
                      </div>
                    )}
                    {showDays && false && (
                      <Typography className="limit-alert">
                        Limit of followups reached
                      </Typography>
                    )}
                  </div>
                )}
                {!followUp && (
                  <div className="switch-off">
                    <Typography>
                      You can setup follow-up reminders for all people who
                      confirmed attendance.
                    </Typography>
                  </div>
                )}
              </Typography>

              {followUp && showDays && (
                <div className="mt-5">
                  <Typography
                    component="h3"
                    className={`switches-title ${
                      onConnectPcoModal ? "p-0" : ""
                    }`}
                  >
                    <ReminderIcon className="mr-2" /> Enable Smart Texting
                  </Typography>
                  {combination && (
                    <div className="switch-control-two">
                      <Tooltip title="Turning on this option would always send one reminder text per day for all the services the person is scheduled for. We would use the first (earliest) event date/time as a reference while mentioning the other service times in the same text.">
                        <Typography component="h3">
                          Should we combine all the person’s service reminders
                          for that day into one text?
                        </Typography>
                      </Tooltip>
                      <Tooltip
                        title={
                          locked && !defaults[combination.schedules]
                            ? "Not available in this plan"
                            : "Turning on this option would always send one reminder text per day for all the services the person is scheduled for. We would use the first (earliest) event date/time as a reference while mentioning the other service times in the same text."
                        }
                      >
                        <div>
                          {locked && !defaults[combination.schedules] && (
                            <Icon>lock</Icon>
                          )}
                          <Switch
                            name={combination.name}
                            //onChange={this.handleChange}
                            checked={combination.is_default === 1}
                            disabled={
                              locked && !defaults[combination.schedules]
                            }
                            onChange={() => this.handleSwitch(combination.id)}
                            value={combination.is_default}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            disableRipple
                            classes={{
                              root: "custom-switch",
                              thumb: "thumb",
                              track: "track",
                              switchBase: "switch-base",
                              checked: "checked",
                            }}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {!isEmptyObject(scheduleTemplateToAssignFolders) && (
          <AssignTemplateModal
            onConnectPcoModal={onConnectPcoModal}
            showModal={!isEmptyObject(scheduleTemplateToAssignFolders)}
            toggleModal={this.setScheduleTemplateToAssignFolders({})}
            scheduleTemplate={scheduleTemplateToAssignFolders}
          />
        )}
        {snackbarData?.showSnackBar && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbarData?.showSnackBar}
            autoHideDuration={6000}
            onClose={this.handleCloseSnackbar}
          >
            <Alert
              onClose={this.closeSnackbar}
              severity={snackbarData?.error ? "error" : "success"}
            >
              {snackbarData?.snackbarMessage}
            </Alert>
          </Snackbar>
        )}
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    assignTemplateStatus: store.services.assignTemplateStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearAssignTemplateStatus: () => dispatch(clearAssignTemplateStatus()),
  getSchedulingServices: (folderId) =>
    dispatch(getSchedulingServicesMiddleware(folderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditSchedule);
