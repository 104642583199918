import React from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  Button,
  Card,
  Grid,
  TextField,
  MenuItem,
  Icon,
  Radio,
  RadioGroup,
  FormControlLabel,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Fab,
  Paper,
  IconButton,
  Menu,
} from "@material-ui/core";
import PropType from "prop-types";
import HeaderMore from "./HeaderMore";
import AddCoupon from "./AddCoupon";
import { userService } from "./../services/UserService";
import PagesSubMenu from "./PagesSubMenu";
import { authHeader } from "./../helpers/AuthHeader";
import "../../styles/components/more/number.scss";
import { toggleConfirmationModal } from "../../store/actions/globalAction";
import ConfirmationModal from "./../ConfirmationModal";
import { connect } from "react-redux";

const API_URL = process.env.REACT_APP_API_URL;

class Coupons extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      coupons: [],
      loading: false,
      error: {},
      anchorEl: null,
      confirmationModalTitle: "",
      confirmationModalDesc: "",
      confirmationAction: null,
    };
    this.handleEdit = this.handleEdit.bind(this);
    this.handleClickMenu = this.handleClickMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  setRemoveConfirmationModalData = () => {
    console.log("sd");
    const { toggleConfirmationModal } = this.props;
    const title = `Remove Coupon`;
    const desc = `Are you sure you want to remove coupon?`;
    this.setState(
      {
        confirmationModalTitle: title,
        confirmationModalDesc: desc,
        confirmationAction: this.handleRemove,
      },
      () => {
        toggleConfirmationModal();
      }
    );
  };

  handleClickMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  componentDidMount() {
    this.refresh();
  }

  handleEdit(e) {
    e.preventDefault();
    console.log(this.state.anchorEl);
    window.location.replace("/edit-coupon?id=" + this.state.anchorEl.id);
  }

  handleRemove = () => {
    this.setState({ loading: true });

    const id = this.state.anchorEl.id;
    this.handleClose();

    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({ id: id }),
    };
    return fetch(`${API_URL}remove-coupon`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          //if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        this.setState({
          loading: false,
        });
        this.refresh();
      })
      .catch(console.log);
  };

  refresh() {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    this.setState({ loading: true });
    return fetch(`${API_URL}coupons`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        this.setState({ coupons: data.coupons, loading: false });
      })
      .catch(console.log);
  }

  render() {
    const {
      anchorEl,
      coupons,
      error,
      confirmationModalDesc,
      confirmationModalTitle,
      confirmationAction,
    } = this.state;
    const { showConfirmationModal } = this.props;

    return (
      <div className="page-wrapper with-scroll numbers-page">
        <PagesSubMenu />
        <HeaderMore backView="/dashboard" title="Coupons" />
        <div className="desktop-view">
          <div className="title-section">
            <Typography className="desktop-title" component={"h2"}>
              Coupons
            </Typography>
            <AddCoupon refresh={this.refresh} />
          </div>
          <TableContainer component={Paper} className="number-page-desktop">
            <Table stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow className="table-head">
                  <TableCell>Code</TableCell>
                  <TableCell align="left">Apply On</TableCell>
                  <TableCell
                    style={{ backgroundColor: "#fafafa" }}
                    align="right"
                  />
                </TableRow>
              </TableHead>
              <TableBody className="table-body-for-numbers">
                {coupons.map((row, idx) => (
                  <TableRow
                    className={row.active === 1 ? "" : "hidden"}
                    key={idx}
                  >
                    <TableCell scope="row">{row.code}</TableCell>
                    <TableCell className="app-column" align="left">
                      {row.apply_when}
                    </TableCell>
                    <TableCell align="right">
                      <div>
                        <IconButton
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          id={row.id}
                          onClick={this.handleClickMenu}
                        >
                          <Icon>more_vert</Icon>
                        </IconButton>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={this.handleClose}
                          classes={{
                            paper: "dropdown-more",
                          }}
                        >
                          <MenuItem onClick={this.handleEdit}>Edit</MenuItem>
                          <MenuItem
                            onClick={this.setRemoveConfirmationModalData}
                          >
                            Remove
                          </MenuItem>
                        </Menu>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="mobile-view">
          <HeaderMore backView="/dashboard" title="Coupons" nextView="#" />
          <div className="choose-number-wrapper">
            {error.errorText !== "" && (
              <div
                className={"alt alert-danger"}
                dangerouslySetInnerHTML={{ __html: error.errorText }}
              />
            )}
            <div>
              {coupons.map((row, idx) => (
                <Card
                  className={
                    row.active === 1 ? "card-single" : "card-single hidden"
                  }
                  key={idx}
                >
                  <div className="top-section">
                    <Typography>{row.code}</Typography>
                    <Typography>{row.apply_when}</Typography>
                    <React.Fragment>
                      <IconButton
                        className="icon-button"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        id={row.id}
                        onClick={this.handleClickMenu}
                      >
                        <Icon>more_vert</Icon>
                      </IconButton>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                        classes={{
                          paper: "dropdown-more",
                        }}
                      >
                        <MenuItem onClick={this.handleEdit}>Edit</MenuItem>
                        <MenuItem onClick={this.setRemoveConfirmationModalData}>
                          Remove
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </div>
        {showConfirmationModal && (
          <ConfirmationModal
            title={confirmationModalTitle}
            description={confirmationModalDesc}
            confirmAction={confirmationAction}
          />
        )}
      </div>
    );
  }
}

Coupons.propTypes = {
  toggleModal: PropType.func,
  showModal: PropType.bool,
};

const mapStateToProps = (store) => {
  return {
    showConfirmationModal: store.global.showConfirmationModal,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleConfirmationModal: () => dispatch(toggleConfirmationModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Coupons);
