import React from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  Button,
  Icon,
  List,
  ListItem,
  ListItemText,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableContainer,
  TableBody,
  Paper,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import HeaderMore from "./HeaderMore";
import PagesSubMenu from "./PagesSubMenu";
import BottomModal from "./../BottomModal";
import { authHeader } from "./../helpers/AuthHeader";
import "../../styles/components/more/schedule-templates.scss";

// var config = require("config");
const API_URL = process.env.REACT_APP_API_URL;
class ScheduleTemplates extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scheduleTemplateList: [],
      loading: false,
      anchorEl: null,
    };
  }

  refreshScheduleTemplates() {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    this.setState({ loading: true });
    return fetch(`${API_URL}schedule-templates`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        this.setState({ scheduleTemplateList: data, loading: false });
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.refreshScheduleTemplates();
  }
  handleClickMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  render() {
    const { scheduleTemplateList, anchorEl } = this.state;
    return (
      <div className="page-wrapper schedule-templates">
        <PagesSubMenu />
        {/*<div className={loading ? "sweet-loading" : ""}>
  		<ClipLoader
  		  css={override}
  		  sizeUnit={"px"}
  		  size={150}
  		  color={'#7BC807'}
  		  loading={loading}
  		/>
  	  <div>*/}
        <HeaderMore
          backView="/dashboard"
          title="Schedule Templates"
          //hasSearch
        />
        <div className="inner-content-wrapper mobile-view">
          <List
            component="nav"
            aria-label="Secondary mailbox folders"
            className="schedule-list"
          >
            {scheduleTemplateList.map(function (scheduleTemplate, index) {
              return (
                <ListItem button className="single-listitem" key={index}>
                  <ListItemText
                    className="label"
                    primary={scheduleTemplate.name}
                  />
                  <div className="right-content">
                    <span className="default">
                      {scheduleTemplate.is_default ? "D" : "C"}
                    </span>
                    <span className="number active">0</span>
                    <BottomModal
                      content={
                        <div>
                          <Button
                            className="content-single"
                            component={Link}
                            to={"/edit-schedule?id=" + scheduleTemplate.id}
                          >
                            Edit
                          </Button>
                          {/*<Button className="content-single">Rename</Button>
          				  <Button className="content-single">Duplicate</Button>
          				  <Button className="content-single">Set as default</Button>
          				  <Button className="content-single">Delete</Button>*/}
                        </div>
                      }
                    />
                  </div>
                </ListItem>
              );
            })}
          </List>
        </div>

        <div className="desktop-view">
          <div className="title-section">
            <Typography className="desktop-title" component={"h2"}>
              Schedule Templates
            </Typography>
            {/*<Button
              color='primary'
              variant='contained'
              component={Link}
              to='/new-template'
              classes={{
              root: 'btn',
              label: 'label',
              containedPrimary: 'contained-primary'
              }}
            >Add new template</Button>*/}
          </div>
          <TableContainer
            component={Paper}
            className="schedule-templates-table"
          >
            <Table stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow className="table-head">
                  <TableCell>Templates name</TableCell>
                  <TableCell align="right" />
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {scheduleTemplateList.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">
                      <span className="default">
                        {row.is_default ? "D" : "C"}
                      </span>
                      {/*<span className="number active">0</span>*/}
                    </TableCell>
                    <TableCell align="right">
                      <div>
                        <IconButton
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          id={row.number}
                          onClick={this.handleClickMenu}
                        >
                          <Icon>more_vert</Icon>
                        </IconButton>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={this.handleClose}
                          classes={{
                            paper: "dropdown-more",
                          }}
                        >
                          {/*<MenuItem onClick={(e) => this.switchPrimary(anchorEl.id)}>Make default</MenuItem>*/}
                          <MenuItem
                            component={Link}
                            to={"/edit-schedule?id=" + row.id}
                          >
                            Edit
                          </MenuItem>
                        </Menu>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/*<Fab color="primary" aria-label="Add" className="fab disabled" component={Link} to="/new-schedule"><Icon>add</Icon></Fab>*/}
      </div>
    );
  }
}
export default ScheduleTemplates;
