import axios from "axios";
import { authHeader } from "../components/helpers/AuthHeader";
import Path from "../config/Path";

export const getAllLogs = async (params) => {
  const { data } = await axios.get(`${Path.GET_ALL_LOGS}?${params}`, {
    headers: authHeader(),
  });
  return data;
};

export const userLogin = async (opts = {}) => {
  const { data } = await axios.post(Path.LOGIN_USER, opts);
  return data;
};

export const getUsers = async (id, isUser) => {
  const { data } = await axios.get(
    `${Path.GET_USERS}/${id}${isUser ? "/1" : ""}`,
    {
      headers: authHeader(),
    }
  );
  return data;
};

export const getChurches = async () => {
  const { data } = await axios.get(Path.GET_CHURCHES, {
    headers: authHeader(),
  });
  return data;
};

export const getImpersonate = async (id) => {
  const { data } = await axios.get(`${Path.GET_IMPERSONATE}/${id}`, {
    headers: authHeader(),
  });
  return data;
};

export const checkProfile = async () => {
  const { data } = await axios.get(Path.CHECK_PROFILE, {
    headers: authHeader(),
  });
  return data;
};

export const updateUserInfo = async (opts = {}) => {
  const { data } = await axios.put(Path.UPDATE_PROFILE, opts, {
    headers: authHeader(),
  });
  return data;
};

export const getMessageTemplates = async () => {
  const { data } = await axios.get(Path.GET_MESSAGE_TEMPLATES, {
    headers: authHeader(),
  });
  return data;
};

export const getDefaultCardApi = async () => {
  const { data } = await axios.get(Path.GET_DEFAULT_CARD, {
    headers: authHeader(),
  });
  return data;
};

export const getCurrentPackage = async () => {
  const { data } = await axios.get(Path.GET_CURRENT_PACKAGE, {
    headers: authHeader(),
  });
  return data;
};

export const loadCards = async () => {
  const { data } = await axios.get(Path.LOAD_CARDS, {
    headers: authHeader(),
  });
  return data;
};

export const loadPayments = async () => {
  const { data } = await axios.get(Path.LOAD_PAYMENTS, {
    headers: authHeader(),
  });
  return data;
};

export const loadCreditsApi = async () => {
  const { data } = await axios.get(Path.LOAD_CREDITS, {
    headers: authHeader(),
  });
  return data;
};

export const cancelSubscription = async (comment) => {
  const { data } = await axios.post(
    Path.CANCEL_SUBSCRIPTION,
    JSON.stringify({ comment: comment }),
    {
      headers: authHeader(),
    }
  );
  return data;
};

export const addCreditsApi = async (opts = {}) => {
  const { data } = await axios.post(Path.ADD_CREDITS, opts, {
    headers: authHeader(),
  });
  return data;
};

export const getScheduleTemplate = async (id = 1) => {
  const { data } = await axios.get(`${Path.GET_SCHEDULE_TEMPLATE}${id}`, {
    headers: authHeader(),
  });
  return data;
};

export const getAllScheduleTemplate = async () => {
  const { data } = await axios.get(`${Path.GET_ALL_SCHEDULE_TEMPLATE}`, {
    headers: authHeader(),
  });
  return data;
};

export const updatePricing = async (id = 1, amount) => {
  const { data } = await axios.post(
    `${Path.UPDATE_PRICING}${id}`,
    JSON.stringify({ amount: amount }),
    {
      headers: authHeader(),
    }
  );
  return data;
};

export const addBonusCredits = async (id, amount, comment) => {
  const { data } = await axios.post(
    `${Path.ADD_BONUS_CREDITS}${id}`,
    JSON.stringify({ amount: amount, comment: comment }),
    {
      headers: authHeader(),
    }
  );
  return data;
};

export const confirmVerification = async (id, approved) => {
  const { data } = await axios.post(
    `${Path.VERIFICATION}${id}/${approved}`,
    null,
    {
      headers: authHeader(),
    }
  );
  return data;
};

export const updateMessageTemplate = async (
  id,
  body,
  multiple_body,
  multiple_body_part,
  additional_part
) => {
  const { data } = await axios.post(
    `${Path.UPDATE_MESSAGE_TEMPLATE}${id}`,
    JSON.stringify({
      body: body,
      multiple_body: multiple_body,
      multiple_body_part: multiple_body_part,
      additional_part: additional_part,
    }),
    {
      headers: authHeader(),
    }
  );
  return data;
};

export const resetMessageTemplate = async (id) => {
  const { data } = await axios.post(
    `${Path.RESET_MESSAGE_TEMPLATE}${id}`,
    null,
    {
      headers: authHeader(),
    }
  );
  return data;
};

export const getKeywords = async () => {
  const { data } = await axios.get(`${Path.GET_KEYWORDS}`, {
    headers: authHeader(),
  });
  return data;
};

export const getFolders = async () => {
  const { data } = await axios.get(`${Path.GET_FOLDERS}`, {
    headers: authHeader(),
  });
  return data;
};

export const getSchedulingServices = async (id = "") => {
  const { data } = await axios.get(`${Path.GET_SCHEDULING_SERVICES}/${id}`, {
    headers: authHeader(),
  });
  return data;
};

export const updateKeyword = async (type, value) => {
  const { data } = await axios.post(
    `${Path.UPDATE_KEYWORD}`,
    JSON.stringify({ type: type, value: value }),
    {
      headers: authHeader(),
    }
  );
  return data;
};

export const resetKeywords = async () => {
  const { data } = await axios.post(`${Path.RESET_KEYWORDS}`, null, {
    headers: authHeader(),
  });
  return data;
};

export const assignTemplate = async (id, serviceIds, folderIds) => {
  const { data } = await axios.post(
    `${Path.ASSIGN_TEMPLATE}/${id}`,
    { serviceIds, folderIds },
    {
      headers: authHeader(),
    }
  );
  return data;
};
