import React from "react";
import { Icon, IconButton } from "@material-ui/core";

class ToggleButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { on: this.props.on };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(e) {
    e.preventDefault();
    this.setState((state) => ({
      on: !state.on,
    }));
  }
  render() {
    return (
      <IconButton
        onClick={this.handleToggle}
        className={this.state.on ? "toggle-btn on" : "toggle-btn off"}
      >
        <Icon className="icon">
          {this.state.on ? this.props.onIcon : this.props.offIcon}
        </Icon>
      </IconButton>
    );
  }
}
export default ToggleButton;
