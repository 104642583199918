import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { connect } from "react-redux";
import PropType from "prop-types";
import { css } from "@emotion/core";
import { Checkbox, Icon } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { getAllScheduleTemplate, getFolders } from "../../services/api";
import { ClipLoader } from "react-spinners";
import {
  assignScheduleTemplateToFoldersOrServices,
  getSchedulingServicesMiddleware,
  setSchedulingServiceData,
} from "../../store/middlewares/servicesMiddleware";
import StatusIconService from "../services/StatusIconService";
import { isEmptyObject, isNotAnEmptyArray } from "../../helpers/helpers";
import ButtonWithSpinner from "../HelperComponents/ButtonWithSpinner";
import { clearAssignTemplateStatus } from "../../store/actions/serviceAction";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;

const AssignTemplateModal = (props) => {
  const {
    showModal,
    toggleModal,
    scheduleTemplate,
    onConnectPcoModal,

    // Redux props
    schedulingServicesData,
    assignTemplateStatus,

    // Redux functions
    getSchedulingServices,
    setSchedulingServiceData,
    assignScheduleTemplateToFoldersOrServices,
    clearAssignTemplateStatus,
  } = props;
  const [folders, setFolders] = useState([]);
  const [serviceListIds, setServiceListIds] = useState([]);
  const [folderId, setFolderId] = useState("");
  const [foldersLoading, setFoldersLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [ScheduleTemplateId, setScheduleTemplateId] = useState('')

  const location = useLocation();

  const disabledButton = useMemo(() => {
    const ids = Object.values(scheduleTemplate?.serviceList)
      ?.filter(Array.isArray)
      ?.flat();
    const allIncluded =
      ids?.every((id) => serviceListIds?.includes(id)) &&
      ids?.length === serviceListIds?.length;
    if (allIncluded) return true;
    return false;
  }, [folders, serviceListIds]);

  useEffect(() => {
    if (assignTemplateStatus === "success") {
      toggleModal();
    }
  }, [assignTemplateStatus, clearAssignTemplateStatus, toggleModal]);

  const handleGetFolders = useCallback(async () => {
    try {
      setFoldersLoading(true);
      setErrorMessage("");
      const defaultFolder = {
        id: 1,
        status: "close",
        title: "Upcoming Services",
      };
      const currentFolders = await getFolders();
      if (currentFolders) {
        currentFolders.unshift(defaultFolder);
        const appendChecked = currentFolders?.map((folder) => {
          folder.checked = scheduleTemplate?.folderList[folder?.title] ?? false;
          if (scheduleTemplate?.folderList?.hasOwnProperty(folder?.title)) {
            getSchedulingServices(folder?.id);
            const ids = Object.values(scheduleTemplate?.serviceList)
              ?.filter(Array.isArray)
              ?.flat();
            setServiceListIds(ids);
          }
          return folder;
        });
        setFolders(appendChecked);
      }
      setFoldersLoading(false);
    } catch (error) {
      setFoldersLoading(false);
      setErrorMessage(error?.message);
    }
  }, []);

  const getAllScheduleTemplates = async () => {
    const templates = await getAllScheduleTemplate();
    if (templates?.length) {
      setScheduleTemplateId(templates.find(template => template.is_default)?.id)
    }
  }

  useEffect(() => {
    handleGetFolders();
  }, []);

  useEffect(() => {
    if (onConnectPcoModal) {
      getAllScheduleTemplates();
    }
  }, [onConnectPcoModal])

  const onChangeFolderChecked = useCallback(
    (folder) => (event) => {
      const { checked } = event.target;
      setFolderId(folder?.id);
      if (!checked) {
        const checkedFolderServices = schedulingServicesData[
          folder?.id
        ]?.data.map((data) => data?.id);
        if (serviceListIds) {
          const updatedStateArray = serviceListIds?.filter(
            (id) => !checkedFolderServices?.includes(id)
          );
          setServiceListIds(updatedStateArray);
          const updatedScheduleService = {
            ...schedulingServicesData,
            [folder.id]: {
              isLoading: false,
              error: null,
            },
          };
          setFolderId("");
          setSchedulingServiceData(updatedScheduleService);
        }
      }

      const updatedFolder = folders.map((item) => {
        if (item.id === folder.id) {
          item.checked = checked;
        }
        return item;
      });
      if (folder?.checked) {
        getSchedulingServices(folder.id);
      }
      setFolders(updatedFolder);
    },
    [folders, getSchedulingServices, schedulingServicesData]
  );

  const onChangeSchedulingService = useCallback(
    (schedulingService) => (event) => {
      const { checked } = event.target;
      const currentScheduling =
        schedulingServicesData[schedulingService.folderId]?.data;

      let updatedServiceList = [];
      if (checked) {
        updatedServiceList = [...serviceListIds, schedulingService?.id];
      } else {
        updatedServiceList = serviceListIds?.filter(
          (id) => id !== schedulingService?.id
        );
      }

      const allServiceIdsIncluded = currentScheduling?.every((item) =>
        updatedServiceList?.includes(item?.id)
      );
      const updatedFolder = folders?.map((item) => {
        if (item.id === schedulingService?.folderId) {
          item.checked = allServiceIdsIncluded;
        }
        return item;
      });

      setFolders(updatedFolder);
      setServiceListIds(updatedServiceList);

      const updatedScheduling = currentScheduling?.map((service) => {
        if (service.id === schedulingService?.id) {
          service.checked = checked;
        }
        return service;
      });

      const updatedScheduleService = {
        ...schedulingServicesData,
        [schedulingService.folderId]: {
          isLoading: false,
          error: null,
          data: updatedScheduling,
        },
      };

      setSchedulingServiceData(updatedScheduleService);
    },
    [schedulingServicesData, setSchedulingServiceData, serviceListIds, folders]
  );

  const getSelectedItemsIds = useCallback((data = []) => {
    return data.map((item) => item.id);
  }, []);

  const getSelectedServicesIds = useCallback((data = {}) => {
    if (isEmptyObject(data)) return [];
    const folderIds = Object.keys(data);
    const servicesIds = folderIds.map((folderId) => {
      const services = data[folderId];
      return services.map((service) => service.id);
    });
    return isNotAnEmptyArray(servicesIds) ? servicesIds.flat() : [];
  }, []);

  const onClickedAssign = useCallback(() => {
    const selectedFolders = folders?.filter((folder) => folder.checked);
    const folderIds = getSelectedItemsIds(selectedFolders);
    const scheduleObj = queryString?.parse(location?.search);
    const id = scheduleObj?.id ? scheduleObj?.id : ScheduleTemplateId;
    assignScheduleTemplateToFoldersOrServices(id, serviceListIds, folderIds);
  }, [
    folders,
    getSelectedItemsIds,
    getSelectedServicesIds,
    location,
    assignScheduleTemplateToFoldersOrServices,
    schedulingServicesData,
  ]);

  useEffect(() => {
    const allServices = schedulingServicesData[folderId]?.data;
    const checkedFolder =
      folders?.find((folder) => folder?.id === folderId)?.checked ?? true;
    if (allServices && checkedFolder) {
      const serviceIds = serviceListIds;
      allServices?.map((service) => {
        if (!serviceListIds?.includes(service?.id)) {
          serviceIds?.push(service?.id);
        }
      });
      setServiceListIds([...serviceIds]);
    }
  }, [schedulingServicesData]);

  return (
    <Dialog
      open={showModal}
      onClose={toggleModal}
      aria-labelledby="form-dialog-title"
      className="assign-template-modal"
      classes={{
        paper: "vw-100",
      }}
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title" className="pb-0">
        <p className="assign-template-modal-heading">
          <b>
            Assign the template ({scheduleTemplate?.template?.name}) to the
            specific folders and services
          </b>
        </p>
        <label className="text-muted assign-modal-label">
          Assigning to a folder will inculde all current and future plans
          automatically
        </label>
      </DialogTitle>
      <IconButton
        className="close-icon"
        style={{ position: "absolute", right: 0 }}
        onClick={toggleModal}
      >
        <Icon>close</Icon>
      </IconButton>
      <DialogContent>
        <div className={`${foldersLoading ? "overflow-hidden" : ""} mt-2`}>
          <div>
            <div className="w-100 folders-heading-container">
              <h5 className="p-0 mb-1">
                <strong>Folders</strong>
              </h5>
            </div>
            <div className="mt-2">
              {errorMessage && (
                <label className="text-center text-danger">
                  {errorMessage}
                </label>
              )}
              <ClipLoader
                css={override}
                sizeUnit={"px"}
                size={80}
                color={"#7BC807"}
                loading={foldersLoading}
              />
              <div>
                {!foldersLoading &&
                  folders &&
                  Array.isArray(folders) &&
                  Boolean(folders.length) &&
                  folders.map((folder, idx) => (
                    <div className="assign-template-folder-container" key={idx}>
                      <div className="assign-template-folder-list">
                        {idx !== 0 && (
                          <Checkbox
                            className="checkbox"
                            checked={folder.checked}
                            onChange={onChangeFolderChecked(folder)}
                            value="selectAll"
                            inputProps={{
                              "aria-label": "primary checkbox",
                            }}
                          />
                        )}
                        {folder.status === "open" && <Icon>folder_open</Icon>}
                        {folder.status === "close" && (
                          <Icon
                            className={`${idx === 0 ? "first-element" : ""}`}
                          >
                            folder
                          </Icon>
                        )}
                        <label className={`mt-2 ml-2`}>
                          {folder?.title || ""}
                        </label>
                      </div>
                      {
                        <div className="schedule-services-container">
                          <div className="vertical-line"></div>
                          {schedulingServicesData[folder.id]?.isLoading && (
                            <div className="pb-2">
                              <ClipLoader
                                css={override}
                                sizeUnit={"px"}
                                size={50}
                                color={"#7BC807"}
                                loading={
                                  schedulingServicesData[folder.id]?.isLoading
                                }
                              />
                            </div>
                          )}
                          <div>
                            {schedulingServicesData[folder.id]?.data &&
                              Array.isArray(
                                schedulingServicesData[folder.id]?.data
                              ) &&
                              Boolean(
                                schedulingServicesData[folder.id]?.data?.length
                              ) &&
                              schedulingServicesData[folder.id]?.data.map(
                                (service, index) => (
                                  <div
                                    key={index}
                                    className="flexer-start schedule-service-list"
                                  >
                                    <div>
                                      <div className="horizontal-line"></div>
                                      <Checkbox
                                        className="checkbox"
                                        checked={serviceListIds.includes(
                                          service.id
                                        )}
                                        onChange={onChangeSchedulingService({
                                          ...service,
                                          folderId: folder.id,
                                        })}
                                        value="selectAll"
                                        inputProps={{
                                          "aria-label": "primary checkbox",
                                        }}
                                      />
                                    </div>
                                    <div className="d-flex align-items-start justify-content-center flex-column">
                                      <label className="text-muted m-0">
                                        {service?.title}{" "}
                                        <span className="ml-2">
                                          <span>
                                            {service.confirmed}/{service.total}
                                          </span>
                                          {service.status && (
                                            <StatusIconService
                                              status={service.status}
                                            />
                                          )}
                                        </span>
                                      </label>
                                      <label className="text-muted small-label">
                                        (
                                        {scheduleTemplate?.template?.name || ""}
                                        ){" "}
                                      </label>
                                    </div>
                                  </div>
                                )
                              )}

                            {schedulingServicesData[folder.id]?.data &&
                              Array.isArray(
                                schedulingServicesData[folder.id]?.data
                              ) &&
                              !Boolean(
                                schedulingServicesData[folder.id]?.data?.length
                              ) && (
                                <div className="text-center text-muted mt-2">
                                  <h4>Services found</h4>
                                </div>
                              )}
                          </div>
                        </div>
                      }
                    </div>
                  ))}
                {!foldersLoading &&
                  folders &&
                  Array.isArray(folders) &&
                  !Boolean(folders.length) && (
                    <div className="text-center text-muted">
                      <h4>No folders found</h4>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="flexer-space-end p-4">
        <div>
          <ButtonWithSpinner
            classes={{
              root: "btn assign-folder-btn",
              containedPrimary: "contained-primary",
            }}
            onClick={onClickedAssign}
            disabled={disabledButton || assignTemplateStatus === "loading"}
            loading={assignTemplateStatus === "loading"}
          >
            Assign
          </ButtonWithSpinner>
        </div>
      </DialogActions>
    </Dialog>
  );
};

AssignTemplateModal.propTypes = {
  toggleModal: PropType.func,
  showModal: PropType.bool,
  scheduleTemplate: PropType.object,
};

AssignTemplateModal.defaultProps = {
  toggleModal: () => {},
  showModal: false,
  scheduleTemplate: {},
};

const mapStateToProps = (store) => {
  return {
    schedulingServicesData: store.services.schedulingServicesData,
    assignTemplateStatus: store.services.assignTemplateStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSchedulingServices: (folderId) =>
    dispatch(getSchedulingServicesMiddleware(folderId)),
  setSchedulingServiceData: (data) => dispatch(setSchedulingServiceData(data)),
  clearAssignTemplateStatus: () => dispatch(clearAssignTemplateStatus()),
  assignScheduleTemplateToFoldersOrServices: (id, serviceIds, folderIds) =>
    dispatch(
      assignScheduleTemplateToFoldersOrServices(id, serviceIds, folderIds)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignTemplateModal);
