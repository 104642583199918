import React from "react";
import { css } from "@emotion/core";
import Moment from "react-moment";
import ClipLoader from "react-spinners/ClipLoader";
import { getCountryCallingCode } from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import {
  Button,
  Box,
  Hidden,
  Typography,
  Grid,
  Snackbar,
  Icon,
} from "@material-ui/core";
import HeaderMore from "./HeaderMore";
import PagesSubMenu from "./PagesSubMenu";
import ConnectAccountModal from "./ConnectAccountModal";
import CustomDropdown from "./../CustomDropdown";
import Pastorsline from "./Pastorsline";
import { authHeader } from "../helpers/AuthHeader";
import { userService } from "../services/UserService";
import DefaultCountryModal from "../DefaultCountryModal";
import DefaultNumberModal from "../DefaultNumberModal";
import "../../styles/components/more/integrations.scss";
import BitleyNoticeModal from "../Modals/BitleyNoticeModal";
import AddNewNumberStepOne from "./AddNewNumberStepOne";

// var config = require("config");
const API_URL = process.env.REACT_APP_API_URL;
const NAMESPACE = process.env.REACT_APP_NAMESPACE;
const BITLY_CLIENT_ID = process.env.REACT_APP_BITLY_CLIENT_ID;
const REDIRECT_URI = "https://" + NAMESPACE + "/bitly-callback";

Moment.globalFormat = "MMMM DD, YYYY hh:mm A";
Moment.globalLocal = true;

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;

const phoneOptions = [
  { key: "mobile", value: "Use mobile" },
  { key: "primary", value: "Use Main/Primary" },
  {
    key: "mobile-primary",
    value: "Try mobile-first and if invalid, send via main/primary",
  },
];

class Active extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      bitly_account: this.props.bitly_account,
      country: this.props.country,
      phonePreference: this.props.phonePreference,
      showDefaultNumModal: false,
      showDefaultCountryModal: false,
      showSnackBar: false,
      snackbarMessage: "",
      showBitleyNoticeModal: false,
      editPl: false,
    };

    this.handleAuthorize = this.handleAuthorize.bind(this);
    this.handleDeauthorize = this.handleDeauthorize.bind(this);
    this.handleDeauthorizePl = this.handleDeauthorizePl.bind(this);
    this.handleEditPl = this.handleEditPl.bind(this);
    this.handleDeauthorizeBitly = this.handleDeauthorizeBitly.bind(this);
    this.handleBitly = this.handleBitly.bind(this);
    this.changeCountry = this.changeCountry.bind(this);
    this.changePhonePreference = this.changePhonePreference.bind(this);
  }

  changeCountry(countryIso) {
    this.setState({ country: countryIso });
  }

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ showSnackBar: false });
  };

  changePhonePreference(e) {
    const phonePreference = e.target.value;
    this.setState({ phonePreference });
  }

  handlePhonePreferenceChange = async () => {
    try {
      const { phonePreference } = this.state;
      if (phonePreference) {
        const data = await userService.savePreference(phonePreference);
        if (data) {
          this.setState({
            showSnackBar: true,
            snackbarMessage: "Number preference updated successfully",
          });
        }
      }
    } catch (error) {
      this.setState({ showSnackBar: true, snackbarMessage: error.message });
    }
  };

  handleDefaultCountryChange = async () => {
    try {
      const { country } = this.state;
      if (country) {
        const data = await userService.saveCountry(
          country,
          getCountryCallingCode(country)
        );
        if (data) {
          this.setState({
            showSnackBar: true,
            snackbarMessage: "Country code updated successfully",
          });
        }
      }
    } catch (error) {
      this.setState({ showSnackBar: true, snackbarMessage: error.message });
    }
  };

  handleDeauthorizeBitly(e) {
    this.setState({ loading: true });
    userService.deactiveBitly().then(
      (user) => {
        //console.log(user);
        //window.location.href = "/integration?d=" + Date.now();
        this.setState({ loading: false, bitly_account: null });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  }

  handleBitly(e) {
    window.open(
      `https://bitly.com/oauth/authorize?client_id=${BITLY_CLIENT_ID}&redirect_uri=${REDIRECT_URI}`,
      "_blank"
    );
  }

  handleDeauthorizePl(e) {
    this.setState({ loading: true });
    userService.deactivePl().then(
      (user) => {
        //console.log(user);
        window.location.href = "/number?d=" + Date.now();
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  }

  handleEditPl(e) {
    this.setState({ loading: true, editPl: true });
  }

  handleDeauthorize(e) {
    this.setState({ loading: true });
    userService.deauthorizePco().then(
      (user) => {
        window.location.reload(false);
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  }

  handleAuthorize(e) {
    this.setState({ loading: true });
    //e.preventDefault();
    userService.authorizePco().then(
      (user) => {},
      (error) => console.log(error)
    );

    return;
  }

  toggleDefaultNumModal = () => {
    this.setState({ showDefaultNumModal: !this.state.showDefaultNumModal });
  };

  toggleDefaultCountryModal = () => {
    this.setState({
      showDefaultCountryModal: !this.state.showDefaultCountryModal,
    });
  };

  toggleBitleyNoticeModal = () => {
    this.setState({ showBitleyNoticeModal: !this.state.showBitleyNoticeModal });
  };

  render() {
    const bitly = this.state.bitly_account;
    const {
      showDefaultCountryModal,
      showDefaultNumModal,
      showSnackBar,
      snackbarMessage,
      showBitleyNoticeModal,
    } = this.state;
    return (
      <React.Fragment>
        <div className="integration-card-active">
          <Typography className="title" component={"h2"}>
            Active
          </Typography>
          <Grid container spacing={2} className="cards">
            {this.state.editPl && (
              <AddNewNumberStepOne
                hasPlNumber={false}
                hasActiveNormalNum={true}
                donotShowButton={true}
              />
            )}
            {this.props.pco_account && (
              <Grid item md={4} xs={12}>
                <Box className="single planning-center">
                  <div className="box-title">
                    <img
                      src="./images/planning-center-integration.png"
                      alt=""
                    />
                    <div className="right-icon">
                      {this.props.pco_account && (
                        <CustomDropdown
                          lists={[
                            {
                              id: 1,
                              title: "Deauthorize",
                              target: "#",
                              onClick: this.handleDeauthorize,
                            },
                            {
                              id: 2,
                              title: "Default country code",
                              target: "#",
                              onClick: this.toggleDefaultCountryModal,
                            },
                            {
                              id: 3,
                              title: "Default number",
                              target: "#",
                              onClick: this.toggleDefaultNumModal,
                            },
                          ]}
                        />
                      )}
                    </div>
                  </div>
                  <div className="content">
                    <Typography>
                      Planning Center Account: {this.props?.pco_account}{" "}
                    </Typography>
                  </div>
                  {this.props.synced && (
                    <Typography className="sync-info">
                      Last Synced:{" "}
                      <Moment format="MM.DD.YYYY">{this.props.synced}</Moment>
                    </Typography>
                  )}
                </Box>
              </Grid>
            )}
            {!this.props.pco_account && (
              <Grid item md={4} xs={12}>
                <Box className="single pastorsline-inactive">
                  <div className="box-title mt">
                    <img
                      src="./images/planning-center-integration.png"
                      alt=""
                    />
                    <div>
                      <CustomDropdown
                        lists={[
                          {
                            id: 1,
                            title: "Default country code",
                            target: "#",
                            onClick: this.toggleDefaultCountryModal,
                          },
                          {
                            id: 2,
                            title: "Default number",
                            target: "#",
                            onClick: this.toggleDefaultNumModal,
                          },
                        ]}
                      />
                    </div>
                    {/*<Icon>more_vert</Icon>*/}
                  </div>
                  <Typography className="content mt-3">
                    <div>
                      Once integrated, your services and people will be synced
                      with Confirmed.Church.
                    </div>
                  </Typography>
                  <div className="box-title" style={{ paddingRight: 10 }}>
                    <ConnectAccountModal connect="pco" />
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      onClick={this.handleAuthorize}
                      classes={{
                        root: "btn",
                        label: "label",
                        containedPrimary: "contained-primary",
                      }}
                    >
                      Connect
                    </Button>
                  </div>
                </Box>
              </Grid>
            )}
            {this.props.pl_account && (
              <Grid item md={4} xs={12}>
                <Box className="single pastorsline-box">
                  <div className="box-title">
                    <img src="./images/pastorsline-integration.png" alt="" />
                    <div className="right-icon">
                      <CustomDropdown
                        lists={[
                          {
                            id: 1,
                            title: "Deauthorize",
                            target: "#",
                            onClick: this.handleDeauthorizePl,
                          },
                          {
                            id: 2,
                            title: "Edit Primary Number",
                            target: "#",
                            onClick: this.handleEditPl,
                          },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="content">
                    <Typography>Pastorsline Account: </Typography>
                    <Typography className="level-two">
                      Number imported and used
                    </Typography>
                  </div>
                </Box>
              </Grid>
            )}
            {!this.props.pl_account && (
              <Grid item md={4} xs={12}>
                <Box className="single pastorsline-inactive">
                  <div className="box-title">
                    <img src="./images/pastorsline-integration.png" alt="" />
                    {/*<Icon>more_vert</Icon>*/}
                  </div>
                  <Typography className="content">
                    If you connect, your PastorsLine number will be imported to
                    be used in your Confirmed.Church account.
                  </Typography>
                  <div className="box-title" style={{ paddingRight: 10 }}>
                    <ConnectAccountModal connect="pastorsline" />
                    <Pastorsline state="open" />
                  </div>
                </Box>
              </Grid>
            )}
            {/*Bitly Account*/}
            {bitly && (
              <Grid item md={4} xs={12}>
                <Box className="single bitly-box">
                  <div className="box-title">
                    <img
                      src="./images/bitly-integration-white.svg"
                      alt=""
                      width="28"
                    />
                    <div className="right-icon">
                      <CustomDropdown
                        lists={[
                          {
                            id: 1,
                            title: "Deauthorize",
                            target: "#",
                            onClick: this.handleDeauthorizeBitly,
                          },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="content">
                    <Typography>Bitly Account: </Typography>
                    <Typography className="level-two">
                      {this.props.bitly_account}
                    </Typography>
                  </div>
                  <div className="flexer">
                    <Button
                      color="primary"
                      variant="text"
                      classes={{
                        root: "btn",
                        label: "label",
                      }}
                      onClick={this.toggleBitleyNoticeModal}
                    >
                      <span className="forgot-pw flexer text-white">
                        <Icon className="mr-1"> help </Icon>Please Read this
                        Notice
                      </span>
                    </Button>
                  </div>
                </Box>
              </Grid>
            )}
            {!bitly && (
              <Grid item md={4} xs={12}>
                <Box className="single bitly-inactive">
                  <div className="box-title">
                    <img
                      src="./images/bitly-integration.svg"
                      alt=""
                      width="28"
                    />
                  </div>
                  <Typography className="content">
                    If you connect, your Bitly Account will be used to shorten
                    all URLs in your Confirmed.Church account.
                  </Typography>
                  <div
                    className="box-title flexer-space-between w-100"
                    style={{ paddingRight: 10 }}
                  >
                    <Button
                      color="primary"
                      variant="text"
                      classes={{
                        root: "btn",
                        label: "label",
                        textPrimary: "text-primary",
                      }}
                      onClick={this.toggleBitleyNoticeModal}
                    >
                      <span className="forgot-pw text-danger flexer">
                        <Icon className="mr-1 text-danger"> help </Icon>Please
                        Read this Notice
                      </span>
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      onClick={this.handleBitly}
                      classes={{
                        root: "btn",
                        label: "label",
                        containedPrimary: "contained-primary",
                      }}
                    >
                      Connect
                    </Button>
                  </div>
                </Box>
              </Grid>
            )}
          </Grid>
          <Typography className="title" component={"h2"}>
            Planned
          </Typography>
          <Grid
            container
            spacing={2}
            className="cards"
            style={{ marginBottom: 80 }}
          >
            {/*<Grid item xs={4}>
                <Box className="single-box">
                <img src="./images/elvanto.svg" alt="" />
                </Box>
              </Grid>*/}
            <Grid item md={4} xs={12}>
              <Box className="single-box">
                <img src="./images/ccb.svg" alt="" />
              </Box>
            </Grid>
          </Grid>
        </div>
        {showDefaultCountryModal && (
          <DefaultCountryModal
            open={showDefaultCountryModal}
            closeModal={this.toggleDefaultCountryModal}
            labels={en}
            value={this.state.country}
            onChange={this.changeCountry}
            onSubmit={this.handleDefaultCountryChange}
            className={this.props.pco_account ? "country-white-select" : ""}
          />
        )}
        {showDefaultNumModal && (
          <DefaultNumberModal
            open={showDefaultNumModal}
            closeModal={this.toggleDefaultNumModal}
            phoneOptions={phoneOptions}
            value={this.state.phonePreference}
            onChange={this.changePhonePreference}
            name={"phone_preference"}
            onSubmit={this.handlePhonePreferenceChange}
            className={this.props.pco_account ? "country-white-select" : ""}
          />
        )}
        {showSnackBar && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={showSnackBar}
            autoHideDuration={6000}
            onClose={this.handleCloseSnackbar}
            message={snackbarMessage}
          />
        )}
        {showBitleyNoticeModal && (
          <BitleyNoticeModal
            handleClose={this.toggleBitleyNoticeModal}
            open={showBitleyNoticeModal}
          />
        )}
      </React.Fragment>
    );
  }
}

class Integrations extends React.Component {
  constructor(props) {
    super(props);

    var user = JSON.parse(localStorage.getItem("user"));
    this.state = {
      account: "",
      integrated: false,
      synced: false,
      pl_account: user.success.user.is_phone_pl ? true : false,
      bitly_account: user.success.user.churches[0].bitly_login,
      country: user.success.user.churches[0].country_iso
        ? user.success.user.churches[0].country_iso
        : "US",
      phonePreference: user.success.user.churches[0].phone_preference
        ? user.success.user.churches[0].phone_preference
        : "mobile-primary",
      loading: false,
    };
  }

  componentDidMount() {
    this.checkIntegration();
  }
  checkIntegration() {
    var _this = this;
    // cache.getItem('integration', function (err, data) {
    // 	if (data) {
    // 		_this.setState({ integrated: data.integrated, account: data.account });
    // 	} else {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    _this.setState({ loading: true });
    return fetch(`${API_URL}pco-profile`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) {
          _this.setState({ loading: false });
        }
        if (!data.organization || data.organization.error) {
          //location.href = "/authorization";
          _this.setState({
            integrated: false,
            account: "",
            synced: false,
            loading: false,
          });
          // cache.setItem(
          // 	'integration',
          // 	{ integrated: false, account: '', synced: false },
          // 	function (err) {}
          // );
        } else {
          _this.setState({
            integrated: true,
            account: data.organization.data.attributes.name,
            synced: data.synced ? data.synced : false,
            loading: false,
          });
          // cache.setItem(
          // 	'integration',
          // 	{
          // 		integrated: true,
          // 		account: data.organization.data.attributes.name,
          // 		synced: data.synced ? data.synced : false,
          // 	},
          // 	function (err) {}
          // );
        }
      })
      .catch(console.log);
    // 	}
    // });
  }

  render() {
    const {
      integrated,
      account,
      synced,
      pl_account,
      bitly_account,
      country,
      phonePreference,
      loading,
    } = this.state;

    return (
      <React.Fragment>
        <div className="page-wrapper integration-page">
          <PagesSubMenu />
          <div className={loading ? "sweet-loading" : ""}>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={150}
              color={"#7BC807"}
              loading={loading}
            />
          </div>
          <Hidden mdUp>
            <HeaderMore backView="/dashboard" title="Integrations" />
          </Hidden>
          <div className="desktop-view">
            <Typography className="desktop-title" component={"h2"}>
              Integrations
            </Typography>
            <div className="integrations">
              {integrated && (
                <Active
                  pco_account={account}
                  synced={synced}
                  pl_account={pl_account}
                  bitly_account={bitly_account}
                  country={country}
                  phonePreference={phonePreference}
                />
              )}
              {!integrated && (
                <Active
                  pl_account={pl_account}
                  country={country}
                  phonePreference={phonePreference}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Integrations;
