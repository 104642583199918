import React from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  Button,
  Icon,
  List,
  ListItem,
  ListItemText,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableContainer,
  TableBody,
  Paper,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import HeaderMore from "./HeaderMore";
import PagesSubMenu from "./PagesSubMenu";
import BottomModal from "./../BottomModal";
import "../../styles/components/more/schedule-templates.scss";
import { getKeywords, resetKeywords } from "../../services/api";

class Keywords extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keywords: [],
      anchorEl: null,
      loading: false,
    };
  }
  handleReset = async () => {
    var data = await resetKeywords();
    this.refreshKeywords();
  };
  handleClickMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  refreshKeywords = async () => {
    var data = await getKeywords();
    this.setState({ keywords: data });
  };

  componentDidMount() {
    this.refreshKeywords();
  }

  render() {
    const { keywords, anchorEl } = this.state;
    return (
      <div className="page-wrapper schedule-templates">
        <PagesSubMenu />
        {/*<div className={loading ? "sweet-loading" : ""}>
  		<ClipLoader
  		  css={override}
  		  sizeUnit={"px"}
  		  size={150}
  		  color={'#7BC807'}
  		  loading={loading}
  		/>
  	  <div>*/}
        <HeaderMore
          backView="/dashboard"
          title="Keywords"
          //hasSearch
        />
        <div className="inner-content-wrapper mobile-view">
          <List
            component="nav"
            aria-label="Secondary mailbox folders"
            className="schedule-list"
          >
            {keywords.map(function (keyword, index) {
              return (
                <ListItem button className="single-listitem" key={index}>
                  <ListItemText className="label" primary={keyword.text} />
                  <div className="right-content">
                    <BottomModal
                      content={
                        <div>
                          <Button
                            className="content-single"
                            component={Link}
                            to={
                              "/edit-keyword?k=" +
                              keyword.type +
                              "-" +
                              keyword.text
                            }
                          >
                            Edit
                          </Button>
                        </div>
                      }
                    />
                  </div>
                </ListItem>
              );
            })}
          </List>
        </div>

        <div className="desktop-view">
          <div className="title-section">
            <Typography className="desktop-title" component={"h2"}>
              Keywords
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleReset}
              classes={{
                root: "btn",
                label: "label",
                containedPrimary: "contained-primary",
              }}
            >
              Reset to Original
            </Button>
          </div>
          <TableContainer
            component={Paper}
            className="schedule-templates-table"
          >
            <Table stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow className="table-head">
                  <TableCell>Keyword</TableCell>
                  <TableCell>Translation</TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {keywords.map((keyword) => (
                  <TableRow key={keyword.type}>
                    <TableCell component="th" scope="row">
                      {keyword.type}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {keyword.text}
                    </TableCell>
                    <TableCell align="right">
                      <div>
                        <IconButton
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          id={`${keyword.type}-${keyword.text}`}
                          onClick={this.handleClickMenu}
                        >
                          <Icon>more_vert</Icon>
                        </IconButton>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={this.handleClose}
                          classes={{
                            paper: "dropdown-more",
                          }}
                        >
                          {/*<MenuItem onClick={(e) => this.switchPrimary(anchorEl.id)}>Make default</MenuItem>*/}
                          {anchorEl ? (
                            <MenuItem
                              component={Link}
                              to={"/edit-keyword?k=" + anchorEl.id}
                            >
                              Edit
                            </MenuItem>
                          ) : (
                            ""
                          )}
                        </Menu>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/*<Fab color="primary" aria-label="Add" className="fab disabled" component={Link} to="/new-schedule"><Icon>add</Icon></Fab>*/}
      </div>
    );
  }
}
export default Keywords;
