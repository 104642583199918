class Path {
  static BASE_URL = process.env.REACT_APP_API_URL;

  static GET_ALL_LOGS = `${this.BASE_URL}all-logs`;

  static LOGIN_USER = `${this.BASE_URL}login`;

  static GET_USERS = `${this.BASE_URL}view-church`;

  static GET_CHURCHES = `${this.BASE_URL}churches`;

  static GET_IMPERSONATE = `${this.BASE_URL}impersonate`;

  static CHECK_PROFILE = `${this.BASE_URL}profile`;

  static UPDATE_PROFILE = `${this.BASE_URL}profile`;

  static GET_MESSAGE_TEMPLATES = `${this.BASE_URL}message-templates`;

  static GET_DEFAULT_CARD = `${this.BASE_URL}default-card`;

  static GET_CURRENT_PACKAGE = `${this.BASE_URL}current-package/1`;

  static LOAD_CARDS = `${this.BASE_URL}cards`;

  static LOAD_PAYMENTS = `${this.BASE_URL}payments`;

  static LOAD_CREDITS = `${this.BASE_URL}credits`;

  static CANCEL_SUBSCRIPTION = `${this.BASE_URL}cancel-subscription`;

  static ADD_CREDITS = `${this.BASE_URL}charge`;

  static GET_SCHEDULE_TEMPLATE = `${this.BASE_URL}schedule-templates/view/`;

  static UPDATE_PRICING = `${this.BASE_URL}country-credits/`;

  static ADD_BONUS_CREDITS = `${this.BASE_URL}credits/`;

  static VERIFICATION = `${this.BASE_URL}verify/`;

  static UPDATE_MESSAGE_TEMPLATE = `${this.BASE_URL}save-message-template/`;

  static RESET_MESSAGE_TEMPLATE = `${this.BASE_URL}reset-message-template/`;

  static GET_KEYWORDS = `${this.BASE_URL}keywords`;

  static UPDATE_KEYWORD = `${this.BASE_URL}edit-keyword`;

  static RESET_KEYWORDS = `${this.BASE_URL}reset-keywords`;

  static GET_FOLDERS = `${this.BASE_URL}scheduling/folders`;

  static GET_SCHEDULING_SERVICES = `${this.BASE_URL}scheduling/services`;

  static ASSIGN_TEMPLATE = `${this.BASE_URL}schedule-templates/assign-template`;

  static GET_ALL_SCHEDULE_TEMPLATE = `${this.BASE_URL}schedule-templates`;
}

export default Path;
